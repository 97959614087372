import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminDashboardElements from "./admin/views/AdminDashboardElements";
import AdminStaffManagement from "./admin/views/AdminStaffManagement";
const AppUsersRouter = () => {
  return (
    <Routes>
      <Route path="" element={<AdminStaffManagement />} />
      {/* <Route path="/staff-management" element={<AdminStaffManagement />} /> */}
    </Routes>
  );
};

export default AppUsersRouter;
