import React, {useEffect, useContext, useState} from 'react';
import {AuthContext} from '../../../App';
import './AdminDashboardElements.scss';
import toast from "react-hot-toast";
import totalSurveyIcon from "../images/totalSurvey.png"
import activeSurvey from "../images/activeSurvey.png"
import currentRating from "../images/currentRating.png"
import responses from "../images/responses.png"
import { FaCircle } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import AppLoader from '../../../learning/user/components/AppLoader';

const AdminDashboardElements = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [dashboardData, setDashboardData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [providerId, setProviderId] = useState('');
  const [id, setId] = useState('')

  let header = {
    Authorization: "Token " + state.token,
  };

  const handleSubmitId = (id) => {
    console.log(id)
    // axios.get('https://api.service.cqc.org.uk/public/v1/providers/' + id, {headers: {'Ocp-Apim-Subscription-Key': '9d322c9f92084c47b2a0605240dccaf7', 'Access-Control-Allow-Origin': 'http://localhost:3000'}})
    // .then(function (response) {
    //   console.log(response);
    // })
    // .catch(function (error) {
    //   console.log(error);
    // })
    // .finally(function () {
    //   always executed
    // });
  }

  return (
    <>
    {loader ? 
    <div className='compliance_admin_dashboard-loader'><AppLoader></AppLoader></div> :
    <div className='compliance_admin_dashboard-elements'>
        <div className='compliance_admin_dashboard-elements-title'>Dashboard</div>
        <div className='compliance_admin_dashboard-elements_body'>
            <div className='compliance_admin_dashboard-elements_body-appInfo'>
                <div className='compliance_admin_dashboard-elements_body-appInfo-container'>
                  <div className='compliance_admin_dashboard-elements_body-appInfo-container-data'>
                        <span className='compliance_admin_dashboard-elements_body-appInfo-container-data-title'>Total Surveys</span>
                        <span className='compliance_admin_dashboard-elements_body-appInfo-container-data-count'>-</span>
                  </div>
                  <div className='compliance_admin_dashboard-elements_body-appInfo-container-icon trainee-icon'>
                        <img src={totalSurveyIcon} alt='trainees'></img>
                  </div>
                </div>
              <div className="compliance_admin_dashboard-elements_body-appInfo-container">
                <div className="compliance_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="compliance_admin_dashboard-elements_body-appInfo-container-data-title">
                    Active Surveys
                  </span>
                  <span className="compliance_admin_dashboard-elements_body-appInfo-container-data-count">
                    -
                  </span>
                </div>
                <div className="compliance_admin_dashboard-elements_body-appInfo-container-icon course-icon">
                  <img src={activeSurvey} alt="courses"></img>
                </div>
              </div>
              <div className="compliance_admin_dashboard-elements_body-appInfo-container">
                <div className="compliance_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="compliance_admin_dashboard-elements_body-appInfo-container-data-title">
                    Current Rating
                  </span>
                  <span className="compliance_admin_dashboard-elements_body-appInfo-container-data-count">
                     -
                  </span>
                </div>
                <div className="compliance_admin_dashboard-elements_body-appInfo-container-icon credit-icon">
                  <img src={currentRating} alt="credit"></img>
                </div>
              </div>
              <div className="compliance_admin_dashboard-elements_body-appInfo-container">
                <div className="compliance_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="compliance_admin_dashboard-elements_body-appInfo-container-data-title">
                    Total Responses
                  </span>
                  <span className="compliance_admin_dashboard-elements_body-appInfo-container-data-count">
                    -
                  </span>
                </div>
                <div className="compliance_admin_dashboard-elements_body-appInfo-container-icon plan-icon">
                  <img src={responses} alt="plan"></img>
                </div>
              </div>
            </div>
            <div className="compliance_admin_dashboard-elements_body-cqcInfo">
            { providerId ? 
              <></> 
              : 
              <div className='compliance_admin_dashboard-elements_body-cqcInfo-providerDetails'>
                <div className='compliance_admin_dashboard-elements_body-cqcInfo-providerDetails-input'>
                  <h3>Submit you Provider ID to view CQC Information.</h3>
                  <input type='input' onChange={(e) => setId(e.target.value)}></input>
                  <button className='start-button' onClick={() => handleSubmitId(id)}>Submit</button>
                </div>
              </div> 
            }
              <div className="compliance_admin_dashboard-elements_body-cqcInfo-title">
                Care Quality Commission (CQC)
              </div>
              <div className="compliance_admin_dashboard-elements_body-cqcInfo-details">
                <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings">
                  <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-title">
                    Current Rating
                  </div>
                  <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body">
                    <ul className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list">
                      <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item">
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key">
                          Caring
                        </div>
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value outstanding">
                          <FaStar />
                          <p>Outstanding</p>
                        </div>
                      </li>
                      <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item">
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key">
                          Effective
                        </div>
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value no-rating">
                          <FaCircle />
                          <p>No Rating</p>
                        </div>
                      </li>
                      <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item">
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key">
                          Responsive
                        </div>
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value good">
                          <FaCircle />
                          <p>Good</p>
                        </div>
                      </li>
                      <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item">
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key">
                          Safe
                        </div>
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value inadequate">
                          <FaCircle />
                          <p>Inadequate</p>
                        </div>
                      </li>
                      <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item">
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-key">
                          Well-led
                        </div>
                        <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratings-body-list-item-value improvement">
                          <FaCircle />
                          <p>Requires Improvement</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-ratingbar good">
                  Overall Good
                </div>
                <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc">
                  <ul className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list">
                    <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item">
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-key">
                        Local Authority
                      </div>
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-value local-authority">
                        London
                      </div>
                    </li>
                    <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item">
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-key">
                        Last Inspection
                      </div>
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-value last-inspection">
                        7 Jul 2024
                      </div>
                    </li>
                    <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item">
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-key">
                        Last Report
                      </div>
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-value last-report">
                        4 Jul 2024
                      </div>
                    </li>
                    <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item">
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-key">
                        Reports
                      </div>
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-value reports">
                        21
                      </div>
                    </li>
                    <li className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item">
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-key">
                        Registration Date
                      </div>
                      <div className="compliance_admin_dashboard-elements_body-cqcInfo-details-desc-list-item-value registration-date">
                        2 Jul 2024
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default AdminDashboardElements;
