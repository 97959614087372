import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminDashboardElements from "./admin/views/AdminDashboardElements";
import AdminRiskDetails from "./admin/views/AdminRiskDetails";
import CreateRiskForm from "./admin/components/CreateRiskForm";

const AppPolicyRouter = () => {
  return (
    <Routes>
      <Route path="" element={<AdminDashboardElements />} />
      <Route path="/risk-details" element={<AdminRiskDetails />} />
      <Route path="/create-risk-form" element={<CreateRiskForm />} />
    </Routes>
  );
};

export default AppPolicyRouter;
