import React from "react";
import { Route, Routes } from "react-router-dom";
import IncidentReportForm from "./admin/components/IncidentReportForm";
import AdminDashboardElements from "./admin/views/AdminDashboardElements";
import AdminIncidentReports from "./admin/views/AdminIncidentReports";
const AppPolicyRouter = () => {
  return (
    <Routes>
      <Route path="" element={<AdminDashboardElements />} />
      <Route path="/incident-reports" element={<AdminIncidentReports />} />
      <Route path="/incident-report-form" element={<IncidentReportForm />} />
    </Routes>
  );
};

export default AppPolicyRouter;
