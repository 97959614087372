import React, { useState, useEffect } from "react";
import {
  FaUpload,
  FaCheck,
  FaUserSlash,
  FaRegFileExcel,
  FaRegCalendarAlt,
  FaHistory,
  FaTimesCircle,
  FaUsers,
  FaBook,
  FaUniversalAccess,
} from "react-icons/fa";
import "./DynamicContainer.scss";

const DynamicContainer = ({
  isFileUpload,
  taskStatus,
  fileData,
  containerData,
  onClose,
}) => {
  const [status, setStatus] = useState("");
  const [fileInfo, setFileInfo] = useState({});

  useEffect(() => {
    if (taskStatus) {
      const status = mapStatus(taskStatus);
      setStatus(status);
    }
    if (fileData) {
      setFileInfo(fileData);
    }
  }, [fileData, taskStatus]);

  const mapStatus = (apiStatus) => {
    switch (apiStatus) {
      case "pending":
        return "Processing";
      case "success":
        return "Completed";
      case "error":
        return "Failed";
      default:
        return "";
    }
  };

  const getStatusClass = () => {
    switch (status) {
      case "Processing":
        return "processing";
      case "Failed":
        return "failed";
      case "Completed":
        return "completed";
      default:
        return "";
    }
  };

  const getStatusIcon = () => {
    if (isFileUpload) {
      switch (status) {
        case "Processing":
          return <FaHistory style={{ color: getStatusTextColor(status) }} />;
        case "Failed":
          return <FaUpload style={{ color: getStatusTextColor(status) }} />;
        case "Completed":
          return <FaCheck style={{ color: getStatusTextColor(status) }} />;
        default:
          return null;
      }
    } else {
      return <FaUsers style={{ color: getStatusTextColor(status) }} />;
    }
  };

  const getStatusTextColor = (status) => {
    if (isFileUpload) {
      switch (status) {
        case "Processing":
          return "#05177D";
        case "Failed":
          return "#D13737";
        case "Completed":
          return "#37D1AE";
        default:
          return "#000";
      }
    } else {
      return "#000";
    }
  };

  const handleFileDownload = () => {
    if (status === "Failed") {
      const link = document.createElement("a");
      link.href = fileInfo.fileUrl;
      link.download = fileInfo.fileName;
      link.click();
    }
  };

  const handleClose = () => {
    onClose();
    setStatus("");
  };

  return (
    <div className={`dynamic-container ${getStatusClass()}`}>
      {(status === "Failed" || status === "Completed") && isFileUpload && (
        <FaTimesCircle
          className="close_button"
          style={{ color: getStatusTextColor(status), cursor: "pointer" }}
          onClick={handleClose}
        />
      )}
      <div className="dynamic-container-body">
        <div className="dynamic-container-body-file_status">
          <div className="dynamic-container-body-file_status-text">
            <div className="dynamic-container-body-file_status-text-title">
              {isFileUpload ? "File Upload" : "Total Users"}
            </div>
            <div
              className="dynamic-container-body-file_status-text-status"
              style={{ color: getStatusTextColor(status) }}
            >
              {isFileUpload ? status : containerData.total_users}
            </div>
          </div>
          <div className="dynamic-container-body-file_status-icon">
            {getStatusIcon()}
          </div>
        </div>
        <div className="dynamic-container-body-file_info">
          <div className="dynamic-container-body-file_info-text">
            <div className="dynamic-container-body-file_info-text-title">
              {isFileUpload ? "File Name" : "Role Count"}
            </div>

            {/* If isFileUpload is false, loop through roles_data */}
            {isFileUpload ? (
              <div
                className="dynamic-container-body-file_info-text-file_name ellipsis"
                title={fileInfo.fileName}
              >
                {fileInfo.fileName}
              </div>
            ) : (
              <div
                className="roles-list"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "25px",
                  overflow: "auto",
                }}
              >
                {containerData.roles_data &&
                  containerData.roles_data.map((role, index) => (
                    <div
                      key={index}
                      className="role-item"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{role.jobrole__name || "No Role"}</span>:
                      <span>{role.count}</span>
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div className="dynamic-container-body-file_info-icon">
            <div className="dynamic-container-body-file_info-icon-svg">
              {isFileUpload ? (
                <FaRegFileExcel style={{ color: getStatusTextColor(status) }} />
              ) : (
                <FaUniversalAccess
                  style={{ color: getStatusTextColor(status) }}
                />
              )}
            </div>
            {isFileUpload && status === "Failed" && (
              <div
                className="dynamic-container-body-file_info-icon-error"
                style={{ color: "black" }}
                onClick={handleFileDownload}
              >
                Check Error
              </div>
            )}
          </div>
        </div>

        <div className="dynamic-container-body-file_date">
          <div className="dynamic-container-body-file_date-text">
            <div className="dynamic-container-body-file_date-text-title">
              {isFileUpload ? "Date" : "Active v/s Inactive Users"}
            </div>
            <div className="dynamic-container-body-file_date-text-date">
              {isFileUpload
                ? fileInfo.date
                : `${containerData.active_users} / ${containerData.inactive_users}`}
            </div>
          </div>
          <div className="dynamic-container-body-file_date-icon">
            {isFileUpload ? (
              <FaRegCalendarAlt style={{ color: getStatusTextColor(status) }} />
            ) : (
              <FaUserSlash style={{ color: getStatusTextColor(status) }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicContainer;
