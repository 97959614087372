import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminEnrollCourses.scss";
import { AuthContext } from "../../../App";
import {
  getAdminCourseData,
  enrollCourse,
  getCourseBundles,
  enrollCourseBundle
} from "../services/api.services";
import GridLayout from "../components/GridLayout";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import PopupModal from "../components/PopupModal";
import { useCredit } from "../components/CreditContext";
import { FaRegEye } from "react-icons/fa";
import "../../../theme/_buttons.scss";

const AdminEnrollCourses = () => {
  const location = useLocation();
  const { state, dispatch } = useContext(AuthContext);
  const [courseData, setCourseData] = useState([]);
  const [bundleData, setBundleData] = useState([]);
  const [enrollCourseIds, setEnrollCourseIds] = useState([]);
  const [enrollBundleIds, setEnrollBundleIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreditNeeded, setIsCreditNeeded] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [selectionType, setSelectionType] = useState(null); // "course" or "bundle"
  const navigate = useNavigate();
  const { fetchCreditDetails, isSubscription } = useCredit();

  const trainees = location.state;

  useEffect(() => {
    getCourseData();
    getCourseBundlesData();
  }, []);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getCourseData = async () => {
    try {
      const response = await getAdminCourseData(header);
      if (response.data.isSuccess) {
        const courseData = response.data.dataInfo;
        setCourseData(courseData);
      } else {
        toast.error("Failed to fetch course data");
      }
    } catch (error) {
      toast.error("Error fetching course data: " + error.message);
    }
  };
  const getCourseBundlesData = async () => {
    try {
      const response = await getCourseBundles(header);
      if (response.status === 200) {
        setBundleData(response.data.results);
      } else {
        toast.error("Failed to fetch course bundles data");
      }
    } catch (error) {
      toast.error("Error fetching course bundle data: " + error.message);
    }
  };

  const handleCourseEnrollToggle = (courseId) => {
    if (selectionType === "bundle") {
      toast.error("Cannot select courses while bundles are selected");
      return;
    }

    setSelectionType("course");
    if (!enrollCourseIds.includes(courseId)) {
      setEnrollCourseIds([...enrollCourseIds, courseId]);
    } else {
      const updatedCourseIds = enrollCourseIds.filter(
        (item) => item !== courseId
      );
      setEnrollCourseIds(updatedCourseIds);

      // Enable both sections if no more courses are selected
      if (updatedCourseIds.length === 0) {
        setSelectionType(null);
      }
    }
  };

  const handleBundleSelect = (bundleId) => {
    if (selectionType === "course") {
      toast.error("Cannot select bundles while courses are selected");
      return;
    }

    setSelectionType("bundle");
    if (!enrollBundleIds.includes(bundleId)) {
      setEnrollBundleIds([...enrollBundleIds, bundleId]);
    } else {
      const updatedBundleIds = enrollBundleIds.filter((id) => id !== bundleId);
      setEnrollBundleIds(updatedBundleIds);

      // Enable both sections if no more bundles are selected
      if (updatedBundleIds.length === 0) {
        setSelectionType(null);
      }
    }
  };

  const countCoursesInBundles = () => {
    return bundleData.reduce((total, bundle) => {
      if (enrollBundleIds.includes(bundle.id)) {
        total += bundle.courses.length;
      }
      return total;
    }, 0);
  };


  const handleTopUpClick = async () => {
    try {
      let response;
  
      if (selectionType === "bundle") {
        const enrollData = {
          bundles: enrollBundleIds.map((id) => id),
          trainees: trainees.map((id) => id),
        };
        response = await enrollCourseBundle(header, enrollData); // Call bundle API
      } else if (selectionType === "course") {
        const enrollData = {
          courses: enrollCourseIds.map((id) => id),
          trainees: trainees.map((id) => id),
        };
        response = await enrollCourse(header, enrollData); // Call course API
      }

      if (response && response.data.isSuccess) {
        toast.success("Enrollment successful");
      } else {
        const errorMessage = response && response.data.message ? response.data.message : "Enrollment failed";
        throw new Error(errorMessage);
      }
  
      if (!isSubscription) {
        fetchCreditDetails();
      }
  
      setIsOpen(false);
      navigate("/learning/admin/trainees");
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || "An unknown error occurred.";
      toast.error("Error enrolling courses: " + errorMessage);
    }
  };
  

  const handleEnrollButton = () => {
    setIsOpen(true);
  };

  const handlePopupCancel = () => {
    setIsOpen(false);
  };

  const handleViewBundle = (bundle) => {
    console.log(bundle);
    setSelectedBundle(bundle);
  };

  const isDisabled = (section) => {
    if (section === "courses" && selectionType === "bundle") return true;
    if (section === "bundles" && selectionType === "course") return true;
    return false;
  };

  const isEnrollButtonDisabled =
    enrollCourseIds.length === 0 && enrollBundleIds.length === 0;

  return (
    <div className="admin-enroll-courses-body">
      <div className="admin-enroll-courses-body_head">
        <h2>Enroll Courses</h2>
        <button
          className="admin-enroll-courses-body_head-button"
          onClick={() => navigate("/learning/admin/trainees")}
        >
          Back
        </button>
      </div>
      <div className="admin-enroll-courses-body_container">
        <div className="admin-enroll-courses-body_container-head">
          <p>
            Select one or more{" "}
            {selectionType === "bundle" ? "bundles" : "courses"} for user
          </p>
          <button
            className={`admin-enroll-courses-body_container-head-button ${
              !isEnrollButtonDisabled ? "active" : ""
            }`}
            disabled={isEnrollButtonDisabled}
            onClick={handleEnrollButton}
          >
            {enrollCourseIds.length
              ? "Enroll Course"
              : enrollBundleIds.length
              ? "Enroll Bundle"
              : "Enroll"}
          </button>
        </div>

        {/* Bundle Courses Section */}
        <div
          className={`bundle-courses-section ${
            isDisabled("bundles") ? "disabled-section" : ""
          }`}
          style={{ opacity: isDisabled("bundles") ? 0.5 : 1 }}
          onClick={() => {
            if (isDisabled("bundles")) {
              toast.error(
                "You cannot select bundles while courses are selected"
              );
            }
          }}
        >
          <h3>Bundle Courses</h3>
          <div className="bundle-course-items">
            {bundleData.map((bundle, index) => (
              <div key={index} className="bundle-course-item-container">
                <div className="bundle-course-item">
                  <div
                    className="bundle-course-image"
                    onClick={() =>
                      !isDisabled("bundles") && handleBundleSelect(bundle.id)
                    }
                  >
                    <div className="bundle-icon-stack">
                      {bundle.courses.slice(0, 4).map((course, index) => (
                        <img
                          key={course.id}
                          src={course.icon}
                          className={`stacked-icon stacked-icon-${index}`}
                        />
                      ))}
                    </div>
                  </div>
                  <div
                    className="bundle-name"
                    style={{
                      backgroundColor: enrollBundleIds.includes(bundle.id)
                        ? "#7a83ba"
                        : "",
                      color: enrollBundleIds.includes(bundle.id) ? "white" : "",
                    }}
                    onClick={() => handleViewBundle(bundle)}
                  >
                    {bundle.name}{" "}
                    <FaRegEye
                      style={{
                        color: enrollBundleIds.includes(bundle.id)
                          ? "white"
                          : "",
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* All Courses Section */}
        <div
          className={`admin-enroll-courses-body_container-body-content ${
            isDisabled("courses") ? "disabled-section" : ""
          }`}
          style={{ opacity: isDisabled("courses") ? 0.5 : 1 }}
        >
          <h3>All Courses</h3>
          <div className="admin-enroll-courses-body_container-body-content-item">
            <GridLayout rows={5}>
              {courseData.map((course, index) => (
                <div
                  className={
                    "admin-enroll-courses-body_container-body-content-item-details-courses " +
                    (enrollCourseIds.includes(course.id) ? "selected" : "")
                  }
                  key={course.id}
                  onClick={() => handleCourseEnrollToggle(course.id, index)}
                >
                  <div className="admin-enroll-courses-body_container-body-content-item-details-courses-img">
                    <img src={course.icon} alt="admin-course"></img>
                  </div>
                  <div className="admin-enroll-courses-body_container-body-content-item-details-courses-text">
                    <h3>{course.name}</h3>
                    <p className="admin-enroll-courses-body_container-body-content-item-details-courses-text-module">
                      {course.module_count} Modules
                    </p>
                    <p>1 individual course credit per trainee</p>
                  </div>
                </div>
              ))}
            </GridLayout>
          </div>
        </div>
      </div>

      {isOpen && (
         <PopupModal setIsOpen={setIsOpen}>
         <div className="admin-enroll-courses_modal">
           <h2 className="admin-enroll-courses_modal-title">Enroll Courses</h2>
           <div className="admin-enroll-courses_modal-cards">
             <div className="admin-enroll-courses_modal-cards-trainees">
               <h5>No. of Trainees</h5>
               <h1>{trainees.length}</h1>
             </div>
             {selectionType === "course" ? (
               <div className="admin-enroll-courses_modal-cards-courses">
                 <h5>No. of Courses</h5>
                 <h1>{enrollCourseIds.length}</h1>
               </div>
             ) : (
               <div className="admin-enroll-courses_modal-cards-courses">
                 <h5>No. of Courses in Selected Bundles</h5>
                 <h1>{countCoursesInBundles()}</h1>
               </div>
             )}
           </div>
           <div className="admin-enroll-courses_modal-buttons">
             <button
               className="admin-enroll-courses_modal-buttons-button"
               onClick={handlePopupCancel}
             >
               Cancel
             </button>
             {isCreditNeeded ? (
               <button
                 id="topup-button"
                 className="admin-enroll-courses_modal-buttons-button"
                 onClick={() => navigate("/learning/admin/credits")}
               >
                 TopUp
               </button>
             ) : (
               <button
                 id="topup-button"
                 className="admin-enroll-courses_modal-buttons-button"
                 onClick={handleTopUpClick}
               >
                 Proceed
               </button>
             )}
           </div>
         </div>
       </PopupModal>
      )}

      {/* Popup for Bundle Courses */}
      {selectedBundle && (
        <PopupModal setIsOpen={() => setSelectedBundle(null)}>
          <div className="bundle-popup">
            <h2>{selectedBundle.name} Courses</h2>
            <div className="bundle-course-list">
              {selectedBundle.courses.map((course, index) => (
                <div key={index} className="bundle-course-list-item">
                  <img
                    src={course.icon}
                    alt="course"
                    className="course-thumbnail"
                  />
                  <div className="bundle-course-info">
                    <h4>{course.name}</h4>
                    <p>{course.module_count} Modules</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminEnrollCourses;
