import React, { useState, useEffect, useRef } from "react";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import "./SearchableDropdown.scss";

const SearchableDropdown = ({
  items,
  defaultText,
  onChange,
  customStyle = {},
  allowNewItems = false,
  onNewItem, // Callback for adding a new item to formData
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState(items);
  const inputRef = useRef(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    item.handleClick();
    setSearchTerm(item.label);
    setIsOpen(false);
  };

  const handleNewItemClick = (newLabel) => {
    const newItem = { label: newLabel, handleClick: () => onNewItem(newLabel) };
    setSelectedItem(newItem);
    onNewItem(newLabel);
    setSearchTerm(newLabel);
    setIsOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest(".searchable-dropdown")) {
      setIsOpen(false);
    }
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      const matches = items.filter((item) =>
        item.label.toLowerCase().includes(lowerCaseTerm)
      );
      setFilteredItems(matches);
    } else {
      setFilteredItems(items);
    }
  }, [searchTerm, items]);

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <div className="searchable-dropdown" style={customStyle}>
      <div
        className="searchable-dropdown__toggle"
        onClick={() => setIsOpen(true)}
      >
        {isOpen ? (
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search..."
            className="searchable-dropdown__input"
            style={{ margin: "0", padding: "0" }}
          />
        ) : (
          <span onClick={() => setIsOpen(true)}>
            {selectedItem ? selectedItem.label : defaultText}
          </span>
        )}
        <FaChevronDown
          className={`chevron-icon ${isOpen ? "rotate" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen && (
        <ul className={`searchable-dropdown__list ${isOpen ? "open" : ""}`}>
          {filteredItems.length
            ? filteredItems.map((item, index) => (
                <li
                  key={index}
                  className="searchable-dropdown__list-item"
                  onClick={() => handleItemClick(item)}
                >
                  {item.label}
                </li>
              ))
            : allowNewItems && searchTerm && (
                <li
                  className="searchable-dropdown__list-item"
                  onClick={() => handleNewItemClick(searchTerm)}
                >
                  {`${searchTerm} (new ${defaultText})`}
                </li>
              )}
        </ul>
      )}
    </div>
  );
};

export default SearchableDropdown;
