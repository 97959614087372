import React, { useState, useEffect, useRef, useContext } from "react";
import "./Dashboard.scss";
import AppSidebar from "../components/AppSidebar";
import AppNavbar from "../components/AppNavbar";
import AppBodyRouter from "../components/AppBodyRouter";
import AppLoader from "../components/AppLoader";
import { useDashboardContext } from "../contexts/DashboardContext";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";
import { getCourseDetails } from "../services/api.services";
import logo from "../../images/icons/logo.png";
import avatar from "../../images/icons/user_avatar.jpg";
import dashIcon from "../../images/icons/dashboard.png";
import settingsIcon from "../../images/icons/settings.png";
import logoutIcon from "../../images/icons/logout.png";
import myLearningsIcon from "../../images/icons/curriculum.png";
import certificateIcon from "../../images/icons/certificate.png";
import goBackIcon from "../../images/icons/go_back.png";

const Dashboard = () => {
  const { state, dispatch } = useContext(AuthContext);
  const { isUpdate } = useDashboardContext();
  // setting this zero makes the dashboard selected automatically
  const [selectedNavbarTab, setSelectedNavbarTab] = useState(0);
  const [navbarData, setNavbarData] = useState([]);
  const [courseData, setCourseData] = useState({});
  const [openMobileNav, setOpenMobileNav] = useState(false);
  const [openMobileSidebar, setOpenMobileSidebar] = useState(false);
  var selectedCourseName = sessionStorage.getItem("userCourseName");
  const [loader, setLoader] = useState(false);

  // useState for finding screenwidth
  const [deviceWidth, setDeviceWidth] = useState(getCurrentScreenWidth());

  // var userDetails = JSON.parse(localStorage.userDetails)
  const [userCourses, setUserCourses] = useState(
    JSON.parse(localStorage.getItem("userCourses"))
  );
  const [currentCourseId, setCurrentCourseId] = useState(
    localStorage.getItem("currentCourseId")
  );
  // var currentCourseId = localStorage.currentCourseId

  useEffect(() => {
    getSelectedCourseDetails();
  }, [isUpdate]);

  const preCurentCourse = useRef();
  useEffect(() => {
    const getSelectedCourseName = async () => {
      userCourses.map((data) => {
        if (data["course"].id === currentCourseId) {
          sessionStorage.setItem("userCourseName", data["course"].name);
        }
      });
    };
    getSelectedCourseName();
    preCurentCourse.current = currentCourseId;
  }, [currentCourseId]);

  useEffect(() => {
    const updateDeviceWidth = () => {
      setDeviceWidth(getCurrentScreenWidth());
    };
    window.addEventListener("resize", updateDeviceWidth);

    return () => {
      window.removeEventListener("resize", updateDeviceWidth);
    };
  }, [deviceWidth]);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getSelectedCourseDetails = async () => {
    await getCourseDetails(header, currentCourseId)
      .then((resp) => {
        if (resp.data.isSuccess) {
          setCourseData(resp.data.dataInfo);
          // console.log(resp.data.dataInfo.course_data);
          getSelectedCourseData(resp.data.dataInfo.course_data);
          setLoader(true);
        } else {
          toast.error("Something went wrong!");
          setLoader(false);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        setLoader(false);
      });
  };

  const getSelectedCourseData = async (data) => {
    setNavbarData([{ name: "Dashboard", icon: dashIcon }]);
    setNavbarData(navbarData => [...navbarData, {name: 'Curriculum', icon: myLearningsIcon}])
    // data.modules.map((data) => {
    //   setNavbarData((navbarData) => [
    //     ...navbarData,
    //     { name: data.name, icon: data.icon },
    //   ]);
    // });

    // Temporary change for demo purposes.
    // The settings page needs to be redesigned
    // Certificate page should be hidden for specific courses based on a flag, instead of hiding it for a single course by ID.

    // setNavbarData(navbarData => [...navbarData, {name: 'Mock Tests', icon: quizIcon}])
    // setNavbarData(navbarData => [...navbarData, {name: 'Settings', icon: settingsIcon}])
    if (data.id !== "134d7ee9-4051-43ca-bf9f-e561ff635334") {
      setNavbarData((navbarData) => [
        ...navbarData,
        { name: "Certificate", icon: certificateIcon },
      ]);
    }
    setNavbarData((navbarData) => [
      ...navbarData,
      { name: "Course Selector", icon: goBackIcon },
    ]);
    setNavbarData((navbarData) => [
      ...navbarData,
      { name: "Logout", icon: logoutIcon },
    ]);
  };

  function getCurrentScreenWidth() {
    return window.innerWidth;
  }

  const handleMobileNav = () => {
    setOpenMobileNav(true);
  };
  const handleMobileSidebar = () => {
    setOpenMobileSidebar(true);
  };

  return (
    <>
      {!loader ? (
        <AppLoader />
      ) : (
        <div className="app-dashboard">
          <div className="app-mobile-header">
            <div
              className="app-mobile-header-hamburger"
              onClick={handleMobileNav}
            >
              <span className="app-mobile-header-hamburger-bar"></span>
              <span className="app-mobile-header-hamburger-bar"></span>
              <span className="app-mobile-header-hamburger-bar"></span>
            </div>
            <div className="app-mobile-header-logo">
              <div className="app-mobile-header-logo-img">
                <img src={logo} alt="logo"></img>
              </div>
            </div>
            <div className="app-mobile-header-sidebar-button">
              <img src={avatar} alt="userimage"></img>
              <div
                className="app-mobile-header-sidebar-button-triangle"
                onClick={handleMobileSidebar}
              ></div>
            </div>
          </div>
          <div className="app-mobile-navbar"></div>
          {courseData !== null ? (
            <div className="app-dashboard_body">
              <AppNavbar
                setOpenMobileNavbar={(e) => setOpenMobileNav(e)}
                navbarData={navbarData}
                deviceWidth={deviceWidth}
                openMobileNav={openMobileNav}
              ></AppNavbar>
              {selectedCourseName && (
                <AppBodyRouter courseData={courseData}></AppBodyRouter>
              )}
            </div>
          ) : (
            <></>
          )}
          <AppSidebar
            courseData={courseData.course_data}
            setSelectedNavbarData={(e) => setSelectedNavbarTab(e)}
            setOpenMobileSidebarMenu={(e) => setOpenMobileSidebar(e)}
            deviceWidth={deviceWidth}
            openMobileSidebar={openMobileSidebar}
          ></AppSidebar>
        </div>
      )}
    </>
  );
};

export default Dashboard;
