import React, { useState } from "react";
import "./ToggleSwitch.scss";

const ToggleSwitch = ({
  checked = false, 
  onChange,       
  customStyles = {} 
}) => {
  const [isToggled, setIsToggled] = useState(checked);

  // Toggle state change handler
  const handleToggle = () => {
    const newState = !isToggled;
    setIsToggled(newState);
    
    if (onChange && typeof onChange === "function") {
      onChange(newState);
    }
  };

 
  const {
    switchBackground = "#ccc", 
    switchCheckedBackground = "#4caf50", 
    sliderBackground = "#fff", 
    sliderCheckedBackground = "#fff" 
  } = customStyles;

  return (
    <div
      className={`toggle-switch ${isToggled ? "checked" : ""}`}
      onClick={handleToggle}
      style={{
        backgroundColor: isToggled ? switchCheckedBackground : switchBackground,
      }}
    >
      <div
        className="toggle-switch__slider"
        style={{
          backgroundColor: isToggled ? sliderCheckedBackground : sliderBackground,
        }}
      />
    </div>
  );
};

export default ToggleSwitch;
