import React from 'react';
import './CourseProgress.scss';

const CourseProgress = (props) => {
    const {completed} = props;
    const {bgcolor} = props;
    const {indicator} = props;

    const containerStyles = {
      'backgroundColor': `${bgcolor}`
    }
    const fillerStyles = {
      width: `${completed}%`,
    };
    const indicatorStyles = {
      'backgroundColor': `${indicator}`,
    }


    return (
      <div className="course-progressbar-container-styles" style={containerStyles}>
        <div style={fillerStyles}  className="course-progressbar-filler-styles">
          <span style={indicatorStyles} className="course-progressbar-filler-styles-progress"></span>
        </div>
      </div>
    );
  };

export default CourseProgress
