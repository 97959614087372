import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  checkSuppressionStatus,
  updateSuppressionStatus,
} from "./services/api.services";
import logo from "../learning/images/icons/logo.png";
import "./EmailSubscription.scss";

const EmailSubscription = () => {
  const [isSuppressed, setIsSuppressed] = useState(false); // To track subscription status
  const [isLoading, setIsLoading] = useState(true); // To show loading state
  const [actionCompleted, setActionCompleted] = useState(false); // To show success message after action
  const navigate = useNavigate();
  const email = new URLSearchParams(window.location.search).get("email"); // Get email from URL params

  // Check suppression status when the component loads
  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const response = await checkSuppressionStatus(email);
        if (response.status === 200) {
          setIsSuppressed(response.data.is_suppressed); // Set suppression status from backend
        }
      } catch (err) {
        toast.error("Error checking subscription status.");
      } finally {
        setIsLoading(false);
      }
    };

    if (email) {
      checkSubscriptionStatus(); // Only check if email is present
    } else {
      toast.error("Email is required.");
    }
  }, [email]);

  // Handle subscription/unsubscription toggle
  const handleSubscriptionToggle = async () => {
    const action = isSuppressed ? "subscribe" : "unsubscribe"; // Determine the action based on the current status

    try {
      const response = await updateSuppressionStatus(email, action); // Pass the action
      if (response.status === 200) {
        setIsSuppressed(!isSuppressed); // Toggle the suppression status locally
        toast.success(
          `Successfully ${isSuppressed ? "subscribed" : "unsubscribed"}`
        ); // Show success message
        setActionCompleted(true); // Show success message below the button
      } else {
        toast.error(`Failed to ${isSuppressed ? "subscribe" : "unsubscribe"}.`); // Handle failure
      }
    } catch (err) {
      toast.error("Error updating subscription status."); // Handle API errors
    }
  };

  // Define button styles based on suppression status
  const buttonStyle = {
    backgroundColor: isSuppressed ? "#45b4be" : "#579bd6", // Background color
    color: "white", // Text color
    border: "none", // No border
    padding: "10px 20px", // Padding
    borderRadius: "5px", // Rounded corners
    cursor: "pointer", // Pointer cursor
    fontSize: "16px", // Font size
  };

  return (
    <div className="email-subscription">
      <div className="email-subscription-body">
        <div className="email-subscription-body-logo">
          <div className="email-subscription-body-logo-img">
            <img src={logo} alt="logo"></img>
          </div>
        </div>
        <div className="email-subscription-body-title">
          Manage your subscription
        </div>

        {isLoading ? (
          <p className="note-text">Loading subscription status...</p>
        ) : (
          <>
            {isSuppressed ? (
              <p className="top-text">
                If you'd like to stay updated about our platform and receive
                exciting news, click the button below to subscribe!
              </p>
            ) : (
              <p className="top-text">
                If you don't want us to send you emails, click the button below,
                and you won't be disturbed again.
              </p>
            )}

            <button style={buttonStyle} onClick={handleSubscriptionToggle}>
              {isSuppressed ? "Subscribe" : "Unsubscribe"}
            </button>

            {actionCompleted && (
              <>
                <div className="success-text">
                  You have successfully{" "}
                  {isSuppressed ? "unsubscribed" : "subscribed"} !!
                </div>
                <div className="note-text">You may now close this tab.</div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EmailSubscription;
