import React, { useState, useContext} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FaArrowLeft,
} from "react-icons/fa";
import "./PolicyTemplateViewer.scss";
import { AuthContext } from "../../../App";
import AppPdfViewer from "../../../learning/user/components/AppPdfViewer";

const PolicyTemplateViewer = () => {
  const { state, dispatch } = useContext(AuthContext);
  let header = {
    Authorization: "Token " + state.token,
  };
  const location = useLocation();
  const navigate = useNavigate();
  const template = location.state;
  return (
    <div className="policy_template_viewer">
      <div className="policy_template_viewer-top">
        <div className="policy_template_viewer-top-icon" onClick={()=> navigate("/policy/admin/policy/")}>
          <FaArrowLeft />
        </div>
        <div className="policy_template_viewer-top-title">
          <div className="policy_template_viewer-top-title-text">Policy Template</div>/
          <div className="policy_template_viewer-top-title-template_name">
            {template.name}
          </div>
        </div>
      </div>
      <div className="policy_template_viewer-body">
        <AppPdfViewer subjectfileUrl={template.file}/>
        {/* <PdfViewer fileUrl={template.file} /> */}
       
      </div>
    </div>
  );
};

export default PolicyTemplateViewer;
