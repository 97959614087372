import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminDashboard from "./admin/views/AdminDashboard";
import AdminSurvey from "./admin/views/AdminSurvey";
import SurveyTemplate from "./admin/views/SurveyTemplate";
import SurveyRecipientList from "./admin/views/SurveyRecipientList";
import SurveyDetailed from "./admin/views/SurveyDetailed";
import AdminDashboardElements from "./admin/views/AdminDashboardElements";
const AppComplianceRouter = () => {
  return (
    <Routes>
      <Route path="" element={<AdminDashboardElements />} />
      <Route path="/survey" element={<AdminSurvey />} />
      <Route path="/survey-templates" element={<SurveyTemplate />} />
      <Route path="/survey-recipient_list" element={<SurveyRecipientList />} />
      <Route path="/survey-details" element={<SurveyDetailed />} />
    </Routes>
  );
};

export default AppComplianceRouter;
