import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaCheckDouble, FaClock, FaExclamationTriangle } from "react-icons/fa";
import "./UserPolicyItems.scss";
import "../../../theme/_buttons.scss";
import incompleteIcon from "../../../learning/images/icons/incomplete.png";

const UserPolicyItems = ({
  userPolicies,
  header,
  setStatus,
  status,
  policyCount,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const statuses = [null, true, false]; // All -> null, Read -> true, Pending -> false
  const navigate = useNavigate();

  // Filter policies based on the current status
  // const userPolicies = userPolicies.filter(
  //   (policy) => !status || policy.status === status
  // );

  useEffect(() => {
    const index = statuses.indexOf(status);
    setActiveIndex(index !== -1 ? index : 0);
  }, [status]);

  const handleTitleBarClick = (index) => {
    setActiveIndex(index);
    console.log("////////////", index, statuses[index])
    setStatus(statuses[index]);
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case true:
        return <FaCheckDouble style={{ color: "green" }} />;
      case false:
        return <FaClock style={{ color: "orange" }} />;
      case "overdue":
        return <FaExclamationTriangle style={{ color: "red" }} />;
      default:
        return null;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case true:
        return "green";
      case false:
        return "orange";
      case "overdue":
        return "red";
      default:
        return "gray";
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="user_policy_item-titlebar">
        <ul className="user_policy_item-titlebar-list">
          {["All Policies", "Read", "Pending"].map((title, index) => (
            <li
              key={index}
              className={`user_policy_item-titlebar-list-item ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => handleTitleBarClick(index)}
            >
              <span className="user_policy_item-titlebar-list-item-left"></span>
              <span className="user_policy_item-titlebar-list-item-right"></span>
              <span className="user_policy_item-titlebar-list-item-center">
                {title}
                {activeIndex === index && (
                  <span className="user_policy_item-titlebar-list-item-center-count">
                    {policyCount}
                  </span>
                )}
              </span>
            </li>
          ))}
        </ul>
      </div>
      {userPolicies.length === 0 ? (
        <div className="empty_container">
          <div className="empty_container-image">
            <img src={incompleteIcon} alt="No Policies in this Category" />
          </div>
          <div className="empty_container-text">
            No Policies in this Category!!
          </div>
        </div>
      ) : (
        <div className="wrapper">
          {userPolicies.map((policy, index) => (
            <div
              key={index}
              className="user_policy_item"
              onClick={() =>
                navigate("/policy/user/user-policy-viewer/", { state: policy })
              }
            >
              <div className="user_policy_item-body">
                <div className="user_policy_item-body-top">
                  <div className="user_policy_item-body-top-name">
                    <div className="user_policy_item-body-top-name-text">
                      {policy.policy.name.length !== 0
                        ? policy.policy.name
                        : policy.policy.policy_number}
                    </div>
                    <div className="user_policy_item-body-top-name-icon">
                      {getStatusIcon(policy.is_read)}
                    </div>
                  </div>
                  <div className="user_policy_item-body-top-date">
                    Last modified on {formatDate(policy.updated_on)}.
                  </div>
                </div>
                <hr
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    border: "none",
                    borderTop: `1px solid ${getStatusColor(policy.is_read)}`,
                  }}
                />
                <div className="user_policy_item-body-bottom">
                  <div className="user_policy_item-body-bottom-info">
                    {/* <div className="user_policy_item-body-bottom-info-item">
                    <div className="user_policy_item-body-bottom-info-item-key">
                      Type:
                    </div>
                    <div className="user_policy_item-body-bottom-info-item-value">
                      {policy.type}
                    </div>
                  </div> */}
                    <div className="user_policy_item-body-bottom-info-item">
                      <div className="user_policy_item-body-bottom-info-item-key">
                        Status:
                      </div>
                      <div
                        className="user_policy_item-body-bottom-info-item-value"
                        style={{
                          color: getStatusColor(policy.is_read),
                          fontWeight: "400",
                        }}
                      >
                        {policy.is_read ? "Read" : "Pending"}
                      </div>
                    </div>
                    <div className="user_policy_item-body-bottom-info-item">
                      <div className="user_policy_item-body-bottom-info-item-key">
                        Author:
                      </div>
                      <div className="user_policy_item-body-bottom-info-item-value">
                        {/* {policy.policy_author} */}John Doe
                      </div>
                    </div>
                    <div className="user_policy_item-body-bottom-info-item">
                      <div className="user_policy_item-body-bottom-info-item-key">
                        Version:
                      </div>
                      <div className="user_policy_item-body-bottom-info-item-value">
                        1.0
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default UserPolicyItems;
