import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaPlus,
  FaRegTrashAlt,
  FaEye,
  FaPencilAlt,
  FaFilter,
} from "react-icons/fa";
import toast from "react-hot-toast";
import AdminCustomCheckbox from "../../../learning/admin/components/AdminCustomCheckbox";
import PopupModal from "../../../learning/admin/components/PopupModal";
import AppPagination from "../../../learning/admin/components/AppPagination";
import SearchBox from "../../../learning/user/components/SearchBox";
import DropdownButton from "../../../common/components/DropdownButton";
import DynamicContainer from "../components/DynamicContainer";
import EditStaffPopup from "../components/EditStaffPopup";
import AddStaffPopup from "../components/AddStaffPopup";
import FileUploadPopup from "../components/FileUploadPopup";
import {
  getUserData,
  getJobroles,
  getManagers,
  deleteStaff,
  getDynamicContainerData,
  getUploadTaskStatus,
} from "../services/api.services";
import { AuthContext } from "../../../App";
import "../../../theme/_buttons.scss";
import "./AdminStaffManagement.scss";

const AdminStaffManagement = () => {
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isRemove, setIsRemove] = useState(false);
  const [key, setKey] = useState(0);
  const [checkedData, setCheckedData] = useState([]);
  const itemClass = checkedData.length > 0 ? "active" : "inactive";
  const [isFilter, setIsFilter] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [staffData, setStaffData] = useState([]);
  const [jobroleData, setJobroleData] = useState([]);
  const [managerData, setManagerData] = useState([]);
  const [containerData, setContainerData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(0);

  const [taskId, setTaskId] = useState(localStorage.getItem("taskId") || "");
  const [taskStatus, setTaskStatus] = useState("");
  const [fileData, setFileData] = useState({});
  const [isFileUpload, setIsFileUpload] = useState(!!taskId || false);

  useEffect(() => {
    getJobroleData();
    getContainerData();
    if (taskId) {
      fetchTaskStatus(taskId);
    }
  }, [isFileUpload]);

  useEffect(() => {
    getStaffData();
  }, [
    key,
    pageNo,
    recordsPerPage,
    selectedDate,
    selectedRole,
    selectedStatus,
    selectedManager,
    searchKey,
  ]);

  let header = {
    Authorization: "Token " + state.token,
  };

  const getStaffData = async () => {
    try {
      const response = await getUserData(
        header,
        pageNo,
        recordsPerPage,
        selectedManager,
        selectedStatus,
        selectedDate,
        selectedRole,
        searchKey
      );
      if (response.status === 200) {
        setDataCount(response.data.count);
        setStaffData(response.data.results);
      } else {
        toast.error("Failed to fetch staff data");
      }
    } catch (error) {
      toast.error("Error fetching staff data: " + error.message);
    }
  };

  const getJobroleData = async () => {
    try {
      const response = await getJobroles(header);
      if (response.status === 200) {
        setJobroleData(response.data.results);
      } else {
        toast.error("Failed to fetch jobrole data");
      }
    } catch (error) {
      toast.error("Error fetching jobrole data: " + error.message);
    }
  };
  const getContainerData = async () => {
    try {
      const response = await getDynamicContainerData(header);
      if (response.isSuccess) {
        setContainerData(response.dataInfo);
      } else {
        toast.error("Failed to fetch container data");
      }
    } catch (error) {
      toast.error("Error fetching container data: " + error.message);
    }
  };

  const roleDropdownItems = [
    { label: "----", handleClick: () => setSelectedRole(null) }, // Placeholder for "None"
    ...jobroleData.map((item) => ({
      label: item.name,
      handleClick: () => setSelectedRole(item.name),
    })),
  ];

  const managerDropdownItems = [
    { label: "----", handleClick: () => setSelectedManager(null) }, // Placeholder for "None"
    ...managerData.map((item) => ({
      label: item.full_name,
      handleClick: () => setSelectedManager(item.id),
    })),
  ];

  const statusItems = [
    { label: "----", handleClick: () => setSelectedStatus(null) }, // Placeholder for "None"
    { label: "Active", handleClick: () => setSelectedStatus(true) },
    { label: "Inactive", handleClick: () => setSelectedStatus(false) },
  ];

  const customDropdownStyle = {
    boxShadow: "0px 0px 7px 1px #d9d9d9",
    fontWeight: 500,
    color: "gray",
    height: "40px",
  };

  const handleRemoveData = async () => {
    if (checkedData.length === 0) {
      toast.error("No items selected for deletion.");
      return;
    }
    const response = await deleteStaff(header, checkedData);
    if (response.status === 200) {
      toast.success("Removed user successfully");
      setCheckedData([]);
      setIsRemove(false);
      setKey(prevKey => prevKey + 1);
    } else {
      toast.error(`Error: ${response.message}`);
    }
  };

  const handleCancelClick = () => {
    setCheckedData([]);
    setIsRemove(false);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleSearch = (searchText) => {
    setSearchKey(searchText);
    return [];
  };
  const handleClearSearch = () => {
    setSearchKey("");
    setKey(prevKey => prevKey + 1);
  };
  const handleValueClick = (value) => {
    setSearchKey(value);
  };

  const clearFilters = () => {
    setSelectedDate(null);
    setSelectedRole(null);
    setSelectedStatus(null);
    setSelectedManager(null);
    setKey(prevKey => prevKey + 1);
  };

  const fetchTaskStatus = async (taskId) => {
    try {
      const response = await getUploadTaskStatus(header, taskId);
      if (response.isSuccess) {
        const status = response.dataInfo.status;
        setTaskStatus(status);
        setFileData({
          fileName: response.dataInfo.filename,
          date: new Date(response.dataInfo.created_on).toLocaleDateString(),
          ...(status === "error" && { fileUrl: response.dataInfo.file }),
        });
      } else {
        setTaskStatus("");
      }
    } catch (error) {
      setTaskStatus("");
    }
  };

  const handleClose = () => {
    setTaskId(null);
    setIsFileUpload(false);
    localStorage.removeItem("taskId");
  };

  return (
    <div className="admin_staff_management">
      <div className="admin_staff_management-title">Staff Management</div>
      <div className="admin_staff_management-body">
        <div className="admin_staff_management-body-content">
          <DynamicContainer
            isFileUpload={isFileUpload}
            taskStatus={taskStatus}
            fileData={fileData}
            containerData={containerData}
            onClose={handleClose}
          />
          <div className="admin_staff_management-body-content-buttons">
            
          </div>
          <div className="admin_staff_management-body-content-search_filter">
          <FileUploadPopup header={header} setIsFileUpload={setIsFileUpload} setTaskId={setTaskId}/>
            <AddStaffPopup
              header={header}
              jobroles={jobroleData}
              managers={managerData}
            />
            <button
              className={` ${
                isFilter ? "tertiary-button" : "secondary-button"
              }`}
              onClick={() => {
                setIsFilter(!isFilter);
                clearFilters();
                setPageNo(1);
              }}
            >
              <FaFilter /> Filter
            </button>
            <div className="admin_staff_management-body-content-search_filter-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleSearch}
                onValueClick={handleValueClick}
                onClearSearch={handleClearSearch}
                useDynamicSearch={true}
              />
            </div>
          </div>
          {isFilter && (
            <div className="admin_staff_management-body-content-dropdowns">
              {/* Date Filter */}
              {/* <div className="dropdown-filter">
                <label>Date:</label>
                <input
                  type="date"
                  value={
                    selectedDate ? selectedDate.toISOString().split("T")[0] : ""
                  }
                  onChange={(e) =>
                    setSelectedDate(
                      e.target.value ? new Date(e.target.value) : null
                    )
                  }
                />
                
              </div> */}

              <DropdownButton
                text="Role"
                items={roleDropdownItems}
                customStyle={customDropdownStyle}
              />

              <DropdownButton
                text="Status"
                items={statusItems}
                customStyle={customDropdownStyle}
              />
              <DropdownButton
                text="Assigned Manager"
                items={managerDropdownItems}
                customStyle={customDropdownStyle}
              />
            </div>
          )}

          <div className="admin_staff_management-body-content-table">
            <div className="admin_staff_management-body-content-table-head">
              {staffData && (
                <>
                  <div className="admin_staff_management-body-content-table-head-item">
                    <AdminCustomCheckbox
                      selectAll={true}
                      setCheckedData={setCheckedData}
                      checkedData={checkedData}
                      responseData={staffData}
                    />
                    Name
                  </div>
                  <div className="admin_staff_management-body-content-table-head-item">
                    Role
                  </div>
                  <div className="admin_staff_management-body-content-table-head-item">
                    Status
                  </div>
                  <div className="admin_staff_management-body-content-table-head-item">
                    Assigned Manager
                  </div>
                  <div className="admin_staff_management-body-content-table-head-item">
                    <FaRegTrashAlt
                      onClick={() => {
                        checkedData.length > 0
                          ? setIsRemove(true)
                          : handleRemoveData();
                      }}
                      className={itemClass}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="admin_staff_management-body-content-table-body">
              {staffData &&
                staffData.map((item, index) => (
                  <div
                    className="admin_staff_management-body-content-table-body-row"
                    key={index}
                  >
                    <div className="admin_staff_management-body-content-table-body-row-item">
                      <AdminCustomCheckbox
                        selectAll={false}
                        data_id={item.id}
                        setCheckedData={setCheckedData}
                        checkedData={checkedData}
                      />
                      <div className="admin_staff_management-body-content-table-body-row-item-text">
                        <div className="admin_staff_management-body-content-table-body-row-item-text-main">
                          {item.full_name}
                        </div>
                        <div className="admin_staff_management-body-content-table-body-row-item-text-sub">
                          {item.email}
                        </div>
                        <div
                          className="admin_staff_management-body-content-table-body-row-item-text-sub"
                          style={{ color: "gray" }}
                        >
                          Added on {formatDate(item.created_on)}
                        </div>
                      </div>
                    </div>
                    <div className="admin_staff_management-body-content-table-body-row-item">
                      {item.jobrole}
                    </div>
                    <div className="admin_staff_management-body-content-table-body-row-item">
                      {item.status}
                    </div>
                    <div className="admin_staff_management-body-content-table-body-row-item">
                      {item.manager ? item.manager.full_name : "Unassigned"}
                    </div>
                    <div className="admin_staff_management-body-content-table-body-row-item">
                      <EditStaffPopup
                        header={header}
                        data={item}
                        jobroles={jobroleData}
                        refreshKey={key}
                        setKey={setKey}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {dataCount > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      </div>

      {/* Popup Modal */}
      {isRemove && (
        <PopupModal setIsOpen={setIsRemove}>
          <div className="unenroll_confirm_modal-body">
            <div className="unenroll_confirm_modal-body-title">
              Remove Staff Member(s)
            </div>
            <div className="unenroll_confirm_modal-body-text">
              Are you sure you want to remove selected{" "}
              {checkedData.length < 2 ? "staff member" : "staff members"}?
            </div>
            <div className="unenroll_confirm_modal-body-buttons">
              <button
                className="primary-button"
                onClick={() => {
                  handleRemoveData();
                }}
              >
                Confirm
              </button>
              <button className="secondary-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminStaffManagement;
