import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [selectedNavbarTab, setSelectedNavbarTab] = useState(0);
  const [selectedSidebarTab, setSelectedSidebarTab] = useState(0);   
  const [selectedModule, setSelectedModule] = useState(null);   
  const [selectedChapter, setSelectedChapter] = useState(null);   
  const [classProgress, setClassProgress] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const navigate = useNavigate();
  const handleClassProgress = () => {
    const path = `/${classProgress.course_name}/${classProgress.latest_module_name}`;
    setSelectedNavbarTab(1)
    setSelectedSidebarTab(classProgress.latest_module_name)
    navigate(path);
  };
  return (
    <DashboardContext.Provider
      value={{
        selectedNavbarTab,
        setSelectedNavbarTab,
        selectedSidebarTab,
        setSelectedSidebarTab,
        classProgress,
        setClassProgress,
        selectedModule,
        setSelectedModule,
        selectedChapter,
        setSelectedChapter,
        handleClassProgress,
        isUpdate,
        setIsUpdate,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
export const useDashboardContext = () => useContext(DashboardContext);