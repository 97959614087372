import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});


export const checkSuppressionStatus = async (email) => {
  try {
    const url = `api/marketing/check-email-suppression/?email=${email}`;
    const res = await instance.get(url);
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};
export const updateSuppressionStatus = async (email, action) => {
    try {
        const url = `api/marketing/update-suppression-status/?email=${email}&action=${action}`;
        const res = await instance.post(url); 
        return res;
    } catch (err) {
        return { status: "error", message: err };
    }
};






