import React, { useState, useRef, useEffect } from "react";
import { FaTimes, FaCaretDown, FaCaretUp } from "react-icons/fa";
import "./TagSelectMenu.scss"; // Ensure your CSS file is linked here

const TagSelectMenu = ({ label, options, handleSelectTag, customStyles = {} }) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value) {
      setFilteredOptions(
        options.filter((option) =>
          option.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setFilteredOptions(options);
    }
  };

  const handleTagSelect = (tag) => {
    if (!selectedTags.find((selected) => selected.id === tag.id)) {
      setSelectedTags([...selectedTags, tag]);
    }
    handleSelectTag(tag)
    setInputValue("");
    setFilteredOptions(options);
  };

  const handleTagRemove = (id) => {
    setSelectedTags(selectedTags.filter((tag) => tag.id !== id));
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="tag-select-menu-container"
      style={customStyles.container}
      ref={menuRef}
    >
      <label className="tag-select-menu-label" style={customStyles.label}>
        {label}
      </label>
      <div className="tag-selected-tags" onClick={handleMenuToggle}>
        <div className="tag-items">
          {selectedTags.map((tag) => (
            <div key={tag.id} className="tag-item" style={customStyles.tag}>
              {tag.name}
              <FaTimes
                className="tag-remove-icon"
                onClick={() => handleTagRemove(tag.id)}
              />
            </div>
          ))}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Add or search tags..."
            value={inputValue}
            onChange={handleInputChange}
            className="tag-input"
            onFocus={() => setIsOpen(true)}
            style={customStyles.input}
          />
          {isOpen ? (
            <FaCaretUp className="tag-caret-icon" style={customStyles.icon} />
          ) : (
            <FaCaretDown className="tag-caret-icon" style={customStyles.icon} />
          )}
        </div>
      </div>
      {isOpen && (
        <div className="tag-select-menu-list" style={customStyles.list}>
          {filteredOptions.map((option) => (
            <div
              key={option.id}
              className="tag-option-item"
              style={customStyles.item}
              onClick={() => handleTagSelect(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TagSelectMenu;
