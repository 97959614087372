import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import './IncidentReportForm.scss';

const IncidentReportForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    reporterName: '',
    email: '',
    phoneNumber: '',
    incidentDate: '',
    incidentTime: '',
    location: '',
    incidentType: '',
    personAffected: '',
    reporterRole: '',
    severity: '',
    incidentDescription: '',
    actionTaken: '',
    witnesses: '',
    witnessDetails: '',
    resolution: '',
    fileUpload: null,
    consent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      fileUpload: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Logic to handle form submission
  };

  return (
    <div className="incident-report-form">
    <div className="incident-report-form-top">
        <div
          className="incident-report-form-top-icon"
          onClick={() => navigate("/incident/admin/incident-reports/")}
        >
          <FaArrowLeft />
        </div>
        <div className="incident-report-form-top-title">
          <div className="incident-report-form-top-title-text">Report an Incident</div>
          <div className="incident-report-form-top-title-template_name">
            {/* {template.name} */}
          </div>
        </div>
      </div>
    <form className="incident-report-form-body" onSubmit={handleSubmit}>

      {/* Reporter Details Section */}
      <div className="form-section">
        <div className="form-section-heading">Reporter Details</div>
        <div className="form-fields">
          <div className="half-width">
            <label>Reporter’s Name (Optional)</label>
            <input
              type="text"
              name="reporterName"
              value={formData.reporterName}
              onChange={handleChange}
            />
          </div>
          <div className="half-width">
            <label>Email (Optional)</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="half-width">
            <label>Phone Number (Optional)</label>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      {/* Incident Details Section */}
      <div className="form-section">
        <div className="form-section-heading">Incident Details</div>
        <div className="form-fields">
          <div className="half-width">
            <label>Date of Incident</label>
            <input
              type="date"
              name="incidentDate"
              value={formData.incidentDate}
              onChange={handleChange}
            />
          </div>
          <div className="half-width">
            <label>Time of Incident</label>
            <input
              type="time"
              name="incidentTime"
              value={formData.incidentTime}
              onChange={handleChange}
            />
          </div>
          <div className="half-width">
            <label>Location of Incident</label>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
            />
          </div>
          <div className="half-width">
            <label>Type of Incident</label>
            <select
              name="incidentType"
              value={formData.incidentType}
              onChange={handleChange}
            >
              <option value="">Select Incident Type</option>
              <option value="medicationError">Medication Error</option>
              <option value="patientFall">Patient Fall</option>
              <option value="equipmentFailure">Equipment Failure</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="half-width">
            <label>Severity</label>
            <select
              name="severity"
              value={formData.severity}
              onChange={handleChange}
            >
              <option value="">Select Severity Level</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
          </div>
          <div className="full-width">
            <label>Description of Incident</label>
            <textarea
              name="incidentDescription"
              value={formData.incidentDescription}
              onChange={handleChange}
            />
          </div>
          <div className="full-width">
            <label>Immediate Action Taken</label>
            <textarea
              name="actionTaken"
              value={formData.actionTaken}
              onChange={handleChange}
            />
          </div>
          <div className="half-width">
            <label>Were there any witnesses?</label>
            <select
              name="witnesses"
              value={formData.witnesses}
              onChange={handleChange}
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="full-width file-upload">
            <label>Upload File (Optional)</label>
            <input type="file" name="fileUpload" onChange={handleFileChange} />
          </div>
        </div>
      </div>

      {/* Consent and Submit */}
      <div className="form-section">
        <div className="form-section-heading">Acknowledgement</div>
        <div className="consent-checkbox">
          <input
            type="checkbox"
            name="consent"
            checked={formData.consent}
            onChange={handleChange}
          />
          <label>I consent to be contacted for further information.</label>
        </div>
      </div>

      {/* Submit Button */}
      <div className="form-footer">
        <button type="submit">Submit Report</button>
      </div>
    </form>
    </div>
  );
};

export default IncidentReportForm;
