import React, { useState } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { AuthContext } from "../../../App";
import "./AdminDashboardElements.scss";
import toast from "react-hot-toast";
import totalRiskIcon from "../images/totalRisk.png"
import mitigatedRiskIcon from "../images/mitigatedRisk.png"
import ongoingRiskIcon from "../images/ongoingRisk.png"
import criticalRiskIcon from "../images/criticalRisk.png"
import AppLoader from "../../../learning/user/components/AppLoader";

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const AdminDashboardElements = () => {
  const [dashboardData, setDashboardData] = useState({
    totalRisksRegistered: 150,
    mitigatedRisks: 120,
    ongoingRisks: 20,
    criticalRisks: 10,
    donutChartData: {
      labels: ["Mitigated", "Ongoing", "Critical"],
      datasets: [
        {
          label: "# of Risks",
          data: [120, 20, 10], // Risk data for donut chart
          backgroundColor: ["#4caf50", "#ffeb3b", "#f44336"], // Colors representing mitigated, ongoing, critical risks
          hoverBackgroundColor: ["#81c784", "#fff59d", "#ef9a9a"],
        },
      ],
    },
    riskSeverityData: {
      labels: ["Low", "Medium", "High", "Critical"], // Risk Severity Levels
      datasets: [
        {
          label: "Risks by Severity",
          data: [60, 50, 30, 10], // Risk severity data
          backgroundColor: ["#8bc34a", "#ffc107", "#ff5722", "#d32f2f"], // Color-coded by severity
          hoverBackgroundColor: ["#aed581", "#ffe082", "#ffab91", "#ef9a9a"],
        },
      ],
    },
  });
  const [loader, setLoader] = useState(false);

  return (
    <>
      {loader ? (
        <div className="risk_admin_dashboard-loader">
          <AppLoader></AppLoader>
        </div>
      ) : (
        <div className="risk_admin_dashboard-elements">
          <div className="risk_admin_dashboard-elements-title">
            Dashboard
          </div>
          <div className="risk_admin_dashboard-elements_body">
            <div className="risk_admin_dashboard-elements_body-appInfo">
              <div className="risk_admin_dashboard-elements_body-appInfo-container">
                <div className="risk_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="risk_admin_dashboard-elements_body-appInfo-container-data-title">
                    Total Risks Registered
                  </span>
                  <span className="risk_admin_dashboard-elements_body-appInfo-container-data-count">
                    150
                  </span>
                </div>
                <div className="risk_admin_dashboard-elements_body-appInfo-container-icon trainee-icon">
                  <img src={totalRiskIcon} alt="totalRiskIcon"></img>
                </div>
              </div>
              <div className="risk_admin_dashboard-elements_body-appInfo-container">
                <div className="risk_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="risk_admin_dashboard-elements_body-appInfo-container-data-title">
                    Mitigated Risks
                  </span>
                  <span className="risk_admin_dashboard-elements_body-appInfo-container-data-count">
                    120
                  </span>
                </div>
                <div className="risk_admin_dashboard-elements_body-appInfo-container-icon course-icon">
                  <img src={mitigatedRiskIcon} alt="mitigatedRiskIcon"></img>
                </div>
              </div>
              <div className="risk_admin_dashboard-elements_body-appInfo-container">
                <div className="risk_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="risk_admin_dashboard-elements_body-appInfo-container-data-title">
                    Ongoing Risks
                  </span>
                  <span className="risk_admin_dashboard-elements_body-appInfo-container-data-count">
                    20
                  </span>
                </div>
                <div className="risk_admin_dashboard-elements_body-appInfo-container-icon credit-icon">
                  <img src={ongoingRiskIcon} alt="ongoingRiskIcon"></img>
                </div>
              </div>
              <div className="risk_admin_dashboard-elements_body-appInfo-container">
                <div className="risk_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="risk_admin_dashboard-elements_body-appInfo-container-data-title">
                    Critical Risks
                  </span>
                  <span className="risk_admin_dashboard-elements_body-appInfo-container-data-count">
                    10
                  </span>
                </div>
                <div className="risk_admin_dashboard-elements_body-appInfo-container-icon plan-icon">
                  <img src={criticalRiskIcon} alt="criticalRiskIcon"></img>
                </div>
              </div>
            </div>

            {/* Split the insights into two sections for Doughnut and Bar chart */}
            <div className="risk_admin_dashboard-elements_body-insights">
              <div className="risk_admin_dashboard-elements_body-insights-title">
                Risk Insights
              </div>

              {/* Two chart containers side by side */}
              <div className="risk_admin_dashboard-elements_body-insights-charts-container" style={{ display: "flex", gap: "20px" }}>
                
                {/* Doughnut Chart for Risk Status */}
                <div className="risk_admin_dashboard-elements_body-insights-charts-container-chart" style={{ width: "300px" }}>
                  <Doughnut data={dashboardData.donutChartData} />
                </div>

                {/* Bar Chart for Risk Severity Breakdown */}
                <div className="risk_admin_dashboard-elements_body-insights-charts-container-chart" style={{ width: "500px" }}>
                  <Bar
                    data={dashboardData.riskSeverityData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: true,
                          position: "top",
                        },
                        tooltip: {
                          enabled: true,
                        },
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: "Number of Risks",
                          },
                        },
                        x: {
                          title: {
                            display: true,
                            text: "Severity Level",
                          },
                        },
                      },
                    }}
                  />
                </div>

              </div> {/* End of insights charts container */}

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDashboardElements;
