import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import "./SurveyRecipientList.scss";
import AdminCustomCheckbox from "../../../learning/admin/components/AdminCustomCheckbox";

const SurveyRecipientList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const recipientList = location.state;
  const [checkedData, setCheckedData] = useState([]);
  const recipientListData = [
    {
      id: 1,
      name: "Peter",
      category: "Patient",
      status: "Completed",
    },
    {
      id: 1,
      name: "Anne",
      category: "Relative",
      status: "Pending",
    },
  ];
  return (
    <div className="admin_survey_recipient_list">
      <div className="admin_survey_recipient_list-top">
        <div
          className="admin_survey_recipient_list-top-icon"
          onClick={() => navigate("/compliance/admin/survey/")}
        >
          <FaArrowLeft />
        </div>
        <div className="admin_survey_recipient_list-top-title">
          <div className="admin_survey_recipient_list-top-title-text">
            Template
          </div>
          /
          <div className="admin_survey_recipient_list-top-title-template_name">
            {recipientList.name}
          </div>
        </div>
      </div>
      <div className="admin_survey_recipient_list-body">
        <div className="admin_survey_recipient_list-body-table">
          <div className="admin_survey_recipient_list-body-table-head">
            <div className="admin_survey_recipient_list-body-table-head-item">
              <AdminCustomCheckbox
                selectAll={true}
                setCheckedData={setCheckedData}
                checkedData={checkedData}
                responseData={recipientListData}
              />
              Name
            </div>
            <div className="admin_survey_recipient_list-body-table-head-item">
              Category
            </div>
            <div className="admin_survey_recipient_list-body-table-head-item">
              Status
            </div>
          </div>
          <div className="admin_survey_recipient_list-body-table-body">
            {recipientListData &&
              recipientListData.map((item) => (
                <div
                  className="admin_survey_recipient_list-body-table-body-row"
                  key={item.id}
                >
                  <div className="admin_survey_recipient_list-body-table-body-row-item">
                    <AdminCustomCheckbox
                      selectAll={false}
                      data_id={item.id}
                      setCheckedData={setCheckedData}
                      checkedData={checkedData}
                    />
                    <div className="admin_survey_recipient_list-body-table-body-row-item-text">
                      <div className="admin_survey_recipient_list-body-table-body-row-item-text-main">
                        {item.name}
                      </div>
                    </div>
                  </div>
                  <div className="admin_survey_recipient_list-body-table-body-row-item">
                    {item.category}
                  </div>
                  <div className="admin_survey_recipient_list-body-table-body-row-item">
                    {item.status}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyRecipientList;
