import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaPlus,
  FaRegTrashAlt,
  FaEye,
  FaPencilAlt,
  FaPhone,
} from "react-icons/fa";
import toast from "react-hot-toast";
import AdminCustomCheckbox from "../../../learning/admin/components/AdminCustomCheckbox";
import PopupModal from "../../../learning/admin/components/PopupModal";
import SearchBox from "../../../learning/user/components/SearchBox";
import AppPagination from "../../../learning/admin/components/AppPagination";
import "../../../theme/_buttons.scss";
import "./AdminRiskDetails.scss";

const AdminRiskDetails = () => {
  const navigate = useNavigate();
  const [isRemove, setIsRemove] = useState(false);
  const [key, setKey] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [checkedData, setCheckedData] = useState([]);
  const [data, setData] = useState([]);
  const itemClass = checkedData.length > 0 ? "active" : "inactive";
  const [requestNames, setRequestNames] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [riskData, setRiskData] = useState([
    {
      id: 1,
      risk: "Data Breach",
      date_added: "2024-09-15",
      source: "External",
      likelihood: 4,
      consequence: 5,
      risk_score: 20, // likelihood * consequence
      risk_rating: "Critical",
      risk_summary: "Potential unauthorized access to sensitive data.",
      prevention: "Implement multi-factor authentication.",
      mitigation: "Ensure timely breach notifications and responses.",
    },
    {
      id: 2,
      risk: "System Downtime",
      date_added: "2024-09-10",
      source: "Internal",
      likelihood: 3,
      consequence: 3,
      risk_score: 9,
      risk_rating: "Medium",
      risk_summary: "Scheduled maintenance might lead to extended downtime.",
      prevention: "Plan maintenance during off-peak hours.",
      mitigation: "Develop backup systems.",
    },
    // Add more risk entries as necessary
  ]);

  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(5);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleNavigate = () => {
    navigate("/risk/admin/create-risk-form/");
  };

  const handleFileDownload = (fileUrl, fileName) => {
    try {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // toast.success("File downloaded successfully!");
    } catch (error) {
      toast.error("Failed to download the file.");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleRemoveData = async () => {
    if (checkedData.length === 0) {
      toast.error("No items selected for deletion.");
      return;
    }
    toast.success("Data removed successfully.");
    setCheckedData([]);
    setIsRemove(false);
    setKey(!key);
  };

  const handleCancelClick = () => {
    setCheckedData([]);
    setIsRemove(false);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setKey(!key);
  };

  const handleRequestSearch = (searchText) => {
    setSearchKey(searchText);
    return requestNames;
  };
  const handleClearRequestSearch = () => {
    setSearchKey("");
    setKey(!key);
  };
  const handleRequestValueClick = (requestName) => {
    setSearchKey(requestName);
  };

  const formatFileName = (url) => {
    return url.split("/").pop().split("?")[0];
  };

  return (
    <div className="admin_risk_details">
      <div className="admin_risk_details-title">Risk Management</div>
      <div className="admin_risk_details-body">
        <div className="admin_risk_details-body-tab-content">
          <div className="admin_risk_details-body-tab-content-top">
            <button
              className="primary-button"
              style={{ width: "150px" }}
              onClick={() => handleNavigate()}
            >
              <FaPlus />
              Register a risk
            </button>

            {/* <div className="admin_risk_details-body-tab-content-top-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleRequestSearch}
                onValueClick={handleRequestValueClick}
                onClearSearch={handleClearRequestSearch}
                useDynamicSearch={true}
              />
            </div> */}
          </div>
          <div className="admin_risk_details-body-tab-content-table">
            <div className="admin_risk_details-body-tab-content-table-head">
              {riskData && (
                <>
                  <div className="admin_risk_details-body-tab-content-table-head-item">
                    <AdminCustomCheckbox
                      selectAll={true}
                      setCheckedData={setCheckedData}
                      checkedData={checkedData}
                      responseData={riskData}
                    />
                    Risk Name
                  </div>

                  <div className="admin_risk_details-body-tab-content-table-head-item">
                    Likelihood
                  </div>

                  <div className="admin_risk_details-body-tab-content-table-head-item">
                    Risk Score
                  </div>

                  <div className="admin_risk_details-body-tab-content-table-head-item">
                    Risk Rating
                  </div>

                  <div className="admin-survey-body-tab-content-table-head-item">
                    <FaRegTrashAlt
                      onClick={() => {
                        checkedData.length > 0
                          ? setIsRemove(true)
                          : handleRemoveData();
                      }}
                      className={itemClass}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="admin_risk_details-body-tab-content-table-body">
              {riskData &&
                riskData.map((item) => (
                  <div
                    className="admin_risk_details-body-tab-content-table-body-row"
                    key={item.id}
                  >
                    <div className="admin_risk_details-body-tab-content-table-body-row-item">
                      <AdminCustomCheckbox
                        selectAll={false}
                        data_id={item.id}
                        setCheckedData={setCheckedData}
                        checkedData={checkedData}
                      />
                      <div className="admin_risk_details-body-tab-content-table-body-row-item-text">
                        <div className="admin_risk_details-body-tab-content-table-body-row-item-text-main">
                          {item.risk}
                        </div>
                        <div className="admin_risk_details-body-tab-content-table-body-row-item-text-sub">
                        Added On : {formatDate(item.date_added)}
                        </div>
                      </div>
                    </div>
                    <div className="admin_risk_details-body-tab-content-table-body-row-item">
                      {item.likelihood}
                    </div>
                    <div className="admin_risk_details-body-tab-content-table-body-row-item">
                      {item.risk_score}
                    </div>
                    <div className="admin_risk_details-body-tab-content-table-body-row-item">
                      {item.risk_rating}
                    </div>
                    <div className="admin_risk_details-body-tab-content-table-body-row-item">
                      <div
                        className={`secondary-button admin_risk_details-body-tab-content-table-body-row-item-button ${
                          openDropdownId === item.id ? "open" : ""
                        }`}
                        style={{ width: "95px" }}
                        onClick={() =>
                          handleFileDownload(
                            item.file,
                            `${formatFileName(item.file)}.jpg`
                          )
                        }
                      >
                        <FaEye /> Preview
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {dataCount > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      </div>

      {/* Popup Modal */}
      {isRemove && (
        <PopupModal setIsOpen={setIsRemove}>
          <div className="unenroll_confirm_modal-body">
            <div className="unenroll_confirm_modal-body-title">
              Remove Incident(s)
            </div>
            <div className="unenroll_confirm_modal-body-text">
              Are you sure you want to remove selected{" "}
              {checkedData.length < 2 ? "incident" : "incidents"}?
            </div>
            <div className="unenroll_confirm_modal-body-buttons">
              <button
                className="primary-button"
                onClick={() => {
                  handleRemoveData();
                }}
              >
                Confirm
              </button>
              <button className="secondary-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminRiskDetails;
