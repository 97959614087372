import React, { useEffect, useState } from "react";
import "./BarGraph.scss";

const BarGraph = ({ data, stepValue = 1, colorData: propColorData }) => {
  const [loaded, setLoaded] = useState(false);
  const maxSteps = 5;

  const maxValue = maxSteps * stepValue;

  const gridLines = Array.from(
    { length: maxSteps + 1 },
    (_, i) => maxValue - i * stepValue
  );

  // Define default color data
  const defaultColorData = [
    { primary: "red", secondary: "#FFDBDB" },
    { primary: "red", secondary: "#FFDBDB" },
    { primary: "orange", secondary: "#FFF0D3" },
    { primary: "green", secondary: "#CCF7D8" },
    { primary: "green", secondary: "white" },
  ];

  // Use defaultColorData if propColorData is not provided
  const colorData = propColorData || defaultColorData;

  // Map the incoming data into an array of { key, value } pairs
  const mappedData = Object.keys(data).map((key) => {
    return { key: key, value: data[key] };
  });

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  }, []);

  return (
    <div className="bar-graph-container">
      <div className="bar-graph">
        <div className="grid-lines">
          {gridLines.map((value, index) => (
            <div className="grid-line" key={index}>
              <span className="grid-line-value">{value}</span>
            </div>
          ))}
        </div>
        <div className="bars">
          {mappedData.map((item, index) => {
            const colors =
              colorData[index] || defaultColorData[defaultColorData.length - 1];

            return (
              <div
                key={index}
                className={`bar ${loaded ? "loaded" : ""}`}
                style={{
                  height: loaded
                    ? `${(item.value / (stepValue * 5)) * 100}%`
                    : "0%",
                  border: `1px solid ${colors.primary}`,
                  backgroundColor: colors.secondary,
                }}
              >
                <span className="bar-key">{item.key}</span>
                <span
                  className="bar-value"
                  style={{
                    backgroundColor: colors.primary, 
                    ...(stepValue > 1 && { fontSize: "11px" }) 
                  }}
                >
                  {item.value}
                  {stepValue > 1 && "%"}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BarGraph;
