import React, {useEffect, useContext, useState} from 'react';
import {AuthContext} from '../../../App';
import './AdminDashboardElements.scss';
import traineeIcon from '../../images/icons/admin/trainee.png';
import coursesIcon from '../../images/icons/admin/course.png';
import creditIcon from '../../images/icons/admin/credit.png';
import planIcon from '../../images/icons/admin/plan.png';
import {getAdminDashboardData} from '../../admin/services/api.services';
import toast from "react-hot-toast";
//import AppLoader from '../../components/AppLoader';
import AppLoader from '../../user/components/AppLoader';

// import CourseProgress from '../../components/CourseProgress';
import CourseProgress from '../../user/components/CourseProgress';


import { FaCircle } from "react-icons/fa";
import { FaStar } from "react-icons/fa";

const AdminDashboardElements = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [dashboardData, setDashboardData] = useState(null);
  const [loader, setLoader] = useState(true);

  let header = {
    Authorization: "Token " + state.token,
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await getAdminDashboardData(header);
        if (response.data.isSuccess) {
          setDashboardData(response.data.dataInfo);
          setLoader(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching credit details:", error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <>
    {loader ? 
    <div className='admin-dashboard-loader'><AppLoader></AppLoader></div> :
    <div className='admin-dashboard-elements'>
        <div className='admin-dashboard-elements-title'>Dashboard</div>
        <div className='admin-dashboard-elements_body'>
            <div className='admin-dashboard-elements_body-appInfo'>
                <div className='admin-dashboard-elements_body-appInfo-container'>
                  <div className='admin-dashboard-elements_body-appInfo-container-data'>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-title'>Total Trainees</span>
                        <span className='admin-dashboard-elements_body-appInfo-container-data-count'>{dashboardData.total_trainees}</span>
                  </div>
                  <div className='admin-dashboard-elements_body-appInfo-container-icon trainee-icon'>
                        <img src={traineeIcon} alt='trainees'></img>
                  </div>
                </div>
              <div className="admin-dashboard-elements_body-appInfo-container">
                <div className="admin-dashboard-elements_body-appInfo-container-data">
                  <span className="admin-dashboard-elements_body-appInfo-container-data-title">
                    Total Enrolled Courses
                  </span>
                  <span className="admin-dashboard-elements_body-appInfo-container-data-count">
                    {dashboardData.total_enrolled_course}
                  </span>
                </div>
                <div className="admin-dashboard-elements_body-appInfo-container-icon course-icon">
                  <img src={coursesIcon} alt="courses"></img>
                </div>
              </div>
              <div className="admin-dashboard-elements_body-appInfo-container">
                <div className="admin-dashboard-elements_body-appInfo-container-data">
                  <span className="admin-dashboard-elements_body-appInfo-container-data-title">
                    Credit Balance
                  </span>
                  <span className="admin-dashboard-elements_body-appInfo-container-data-count">
                    {dashboardData.subscription_plan == "Essential"
                      ? dashboardData.credit_balance
                      : "Unlimited"}
                  </span>
                </div>
                <div className="admin-dashboard-elements_body-appInfo-container-icon credit-icon">
                  <img src={creditIcon} alt="credit"></img>
                </div>
              </div>
              <div className="admin-dashboard-elements_body-appInfo-container">
                <div className="admin-dashboard-elements_body-appInfo-container-data">
                  <span className="admin-dashboard-elements_body-appInfo-container-data-title">
                    Subscription Plan
                  </span>
                  <span className="admin-dashboard-elements_body-appInfo-container-data-count">
                    {dashboardData.subscription_plan}
                  </span>
                </div>
                <div className="admin-dashboard-elements_body-appInfo-container-icon plan-icon">
                  <img src={planIcon} alt="plan"></img>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-elements_body-topInfo">
              <div className="admin-dashboard-elements_body-topInfo-courses">
                <div className="admin-dashboard-elements_body-topInfo-courses-title">
                  <div className="admin-dashboard-elements_body-topInfo-courses-title-left">
                    Top Courses
                  </div>
                  <div className="admin-dashboard-elements_body-topInfo-courses-title-right">
                    Total Enrollees
                  </div>
                </div>
                <div className="admin-dashboard-elements_body-topInfo-courses-data">
                  <ul className="admin-dashboard-elements_body-topInfo-courses-data-list">
                    {dashboardData.top_courses.map((data, index) => {
                      return (
                        <li className="admin-dashboard-elements_body-topInfo-courses-data-list-item" key={index}>
                          <div className="admin-dashboard-elements_body-topInfo-courses-data-list-item-icon firstItem">
                            D
                          </div>
                          <div className="admin-dashboard-elements_body-topInfo-courses-data-list-item-title">
                            {data.course__name}
                          </div>
                          <div className="admin-dashboard-elements_body-topInfo-courses-data-list-item-info">
                            {data.count}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="admin-dashboard-elements_body-topInfo-trainees">
                <div className="admin-dashboard-elements_body-topInfo-trainees-title">
                  <div className="admin-dashboard-elements_body-topInfo-trainees-title-left">
                    Top Trainees
                  </div>
                  <div className="admin-dashboard-elements_body-topInfo-trainees-title-right">
                    Courses Completed
                  </div>
                </div>
                <div className="admin-dashboard-elements_body-topInfo-trainees-data">
                  <ul className="admin-dashboard-elements_body-topInfo-trainees-data-list">
                    {dashboardData.top_trainees.map((data, index) => {
                      return (
                        <li className="admin-dashboard-elements_body-topInfo-trainees-data-list-item" key={index}>
                          <div className="admin-dashboard-elements_body-topInfo-trainees-data-list-item-icon firstItem">
                            LS
                          </div>
                          <div className="admin-dashboard-elements_body-topInfo-trainees-data-list-item-title">
                            {data.trainee_name}
                          </div>
                          <div className="admin-dashboard-elements_body-topInfo-trainees-data-list-item-info">
                            <span className="admin-dashboard-elements_body-topInfo-trainees-data-list-item-info-count">
                              {data.course_completion_count}
                            </span>
                            <span className="admin-dashboard-elements_body-topInfo-trainees-data-list-item-info-desc">
                              Courses
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="admin-dashboard-elements_body-progressInfo">
              <div className="admin-dashboard-elements_body-progressInfo-title">
                Course Progress
              </div>
              <div className="admin-dashboard-elements_body-progressInfo-data">
                {dashboardData.course_progress.map((data, index) => {
                  return (
                    <div className="admin-dashboard-elements_body-progressInfo-data-container" key={index}>
                      <div className="admin-dashboard-elements_body-progressInfo-data-container-title">
                        {data.status}
                      </div>
                      <div className="admin-dashboard-elements_body-progressInfo-data-container-bar">
                        <CourseProgress
                          completed={parseInt(data.percentage)}
                          bgcolor="#DDE0E3"
                          indicator={
                            data.status === "Completed"
                              ? "#067D27"
                              : data.status === "In Progress"
                              ? "#16B2BB"
                              : "#FDB01B"
                          }
                        />
                      </div>
                      <div className="admin-dashboard-elements_body-progressInfo-data-container-completed">
                        <span className="admin-dashboard-elements_body-progressInfo-data-container-completed-percent">
                          {Math.round(data.percentage)}%
                        </span>
                        <span className="admin-dashboard-elements_body-progressInfo-data-container-completed-count">
                          {data.count} Courses
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default AdminDashboardElements;
