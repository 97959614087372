import React, { useState } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { AuthContext } from "../../../App";
import "./AdminDashboardElements.scss";
import toast from "react-hot-toast";
import resolutionTimeIcon from "../images/responseTime.png"
import pendingReportIcon from "../images/pendingReport.png"
import reportReviewIcon from "../images/reportReview.png"
import resolvedIcon from "../images/resolved.png"
import AppLoader from "../../../learning/user/components/AppLoader";

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const AdminDashboardElements = () => {
  const [dashboardData, setDashboardData] = useState({
    incidentsReported: 113,
    actionsTaken: 100,
    pendingReports: 13,
    averageIncidents: 75,
    donutChartData: {
      labels: ["Resolved", "Pending", "In Progress"],
      datasets: [
        {
          label: "# of Incidents",
          data: [80, 13, 20], // Example data for donut chart
          backgroundColor: ["#569cd6", "#37afb9", "#05167c"],
          hoverBackgroundColor: ["#e1f1ff", "#bfe5e9", "#8899ca"],
        },
      ],
    },
    incidentSeverityData: {
      labels: ["Low", "Medium", "High", "Critical"], // Severity Levels
      datasets: [
        {
          label: "Incidents by Severity",
          data: [50, 30, 20, 10], // Example incident severity data
          backgroundColor: ["#4caf50", "#ffeb3b", "#ff9800", "#f44336"], // Color-coded by severity
          hoverBackgroundColor: ["#81c784", "#fff59d", "#ffcc80", "#ef9a9a"],
        },
      ],
    },
  });
  const [loader, setLoader] = useState(false);

  return (
    <>
      {loader ? (
        <div className="incident_admin_dashboard-loader">
          <AppLoader></AppLoader>
        </div>
      ) : (
        <div className="incident_admin_dashboard-elements">
          <div className="incident_admin_dashboard-elements-title">
            Dashboard
          </div>
          <div className="incident_admin_dashboard-elements_body">
            <div className="incident_admin_dashboard-elements_body-appInfo">
              <div className="incident_admin_dashboard-elements_body-appInfo-container">
                <div className="incident_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="incident_admin_dashboard-elements_body-appInfo-container-data-title">
                  Incident Resolution Time
                  </span>
                  <span className="incident_admin_dashboard-elements_body-appInfo-container-data-count">
                    5 Hours
                  </span>
                </div>
                <div className="incident_admin_dashboard-elements_body-appInfo-container-icon trainee-icon">
                  <img src={resolutionTimeIcon} alt="resolutionTimeIcon"></img>
                </div>
              </div>
              <div className="incident_admin_dashboard-elements_body-appInfo-container">
                <div className="incident_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="incident_admin_dashboard-elements_body-appInfo-container-data-title">
                  Resolved Incidents Percentage
                  </span>
                  <span className="incident_admin_dashboard-elements_body-appInfo-container-data-count">
                    85%
                  </span>
                </div>
                <div className="incident_admin_dashboard-elements_body-appInfo-container-icon course-icon">
                  <img src={resolvedIcon} alt="resolvedIcon"></img>
                </div>
              </div>
              <div className="incident_admin_dashboard-elements_body-appInfo-container">
                <div className="incident_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="incident_admin_dashboard-elements_body-appInfo-container-data-title">
                    Pending Reports
                  </span>
                  <span className="incident_admin_dashboard-elements_body-appInfo-container-data-count">
                    13 Incidents
                  </span>
                </div>
                <div className="incident_admin_dashboard-elements_body-appInfo-container-icon credit-icon">
                  <img src={pendingReportIcon} alt="pendingReportIcon"></img>
                </div>
              </div>
              <div className="incident_admin_dashboard-elements_body-appInfo-container">
                <div className="incident_admin_dashboard-elements_body-appInfo-container-data">
                  <span className="incident_admin_dashboard-elements_body-appInfo-container-data-title">
                  Incidents in Review
                  </span>
                  <span className="incident_admin_dashboard-elements_body-appInfo-container-data-count">
                    10
                  </span>
                </div>
                <div className="incident_admin_dashboard-elements_body-appInfo-container-icon plan-icon">
                  <img src={reportReviewIcon} alt="reportReviewIcon"></img>
                </div>
              </div>
            </div>
            
            {/* Split the insights into two sections for Doughnut and Bar chart */}
            <div className="incident_admin_dashboard-elements_body-insights">
              <div className="incident_admin_dashboard-elements_body-insights-title">
                Incident Insights
              </div>

              {/* Two chart containers side by side */}
              <div className="incident_admin_dashboard-elements_body-insights-charts-container" style={{ display: "flex", gap: "20px" }}>
                
                {/* Doughnut Chart for Incident Resolution Status */}
                <div className="incident_admin_dashboard-elements_body-insights-charts-container-chart" style={{ width: "300px" }}>
                  <Doughnut data={dashboardData.donutChartData} />
                </div>

                {/* Bar Chart for Incident Severity Breakdown */}
                <div className="incident_admin_dashboard-elements_body-insights-charts-container-chart" style={{ width: "500px" }}>
                  <Bar
                    data={dashboardData.incidentSeverityData}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: true,
                          position: "top",
                        },
                        tooltip: {
                          enabled: true,
                        },
                      },
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: "Number of Incidents",
                          },
                        },
                        x: {
                          title: {
                            display: true,
                            text: "Severity Level",
                          },
                        },
                      },
                    }}
                  />
                </div>

              </div> {/* End of insights charts container */}

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDashboardElements;
