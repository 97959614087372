import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminDashboard from "./admin/views/AdminDashboard";
import AdminPolicy from "./admin/views/AdminPolicy";
import CreatePolicy from "./admin/components/CreatePolicy";
import PolicyTemplateViewer from "./admin/components/PolicyTemplateViewer";
import AdminDashboardElements from "./admin/views/AdminDashboardElements";
import UserPolicyViewer from "./user/components/UserPolicyViewer";
import PolicyUser from "./user/views/PolicyUser";
import UserPolicy from "./user/views/UserPolicy";

const AppUserPolicyRouter = () => {
  return (
    <Routes>
      {/* <Route path="" element={<AdminDashboardElements />} />
      <Route path="/policy" element={<AdminPolicy />} />
      <Route path="/create-policy" element={<CreatePolicy />} />
      <Route path="/policy-template-view" element={<PolicyTemplateViewer />} /> */}
      <Route path="" element={<UserPolicy />} />
      <Route path="/User-policy-viewer" element={<UserPolicyViewer />} />
      
      
      
    </Routes>
  );
};

export default AppUserPolicyRouter;
