import React, { useState, useEffect, useContext, useRef } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../../App";
import AppLoader from "../../../learning/user/components/AppLoader";
import SearchBox from "../../../learning/user/components/SearchBox";
import UserPolicyItems from "../components/UserPolicyItems";
import AppPagination from "../../../learning/admin/components/AppPagination";
import {
  getUserPolicytData,
  searchUserPolicyData,
  updateUserPolicy
} from "../services/api.services";
import "./UserPolicy.scss";

const UserPolicy = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [policyNames, setPolicyNames] = useState([]);
  const [status, setStatus] = useState(null);
  const [count, setCount] = useState(8);
  const [isRead, setIsRead] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [policyData, setPolicyData] = useState([]);


  var recordsPerPage = 10;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(0);

  useEffect(() => {
    if (searchText) {
      findUserPolicy(searchText);
    } else {
      fetchUserPolicy();
    }
  }, [isRead, searchText, pageNo]);

  const fetchUserPolicy = async () => {
    // setLoader(false);
    try {
      let response;
      if (isRead!== null) {
        response = await getUserPolicytData(
          header,
          isRead,
          pageNo,
          recordsPerPage
        );
      } else {
        response = await getUserPolicytData(
          header,
          null,
          pageNo,
          recordsPerPage
        );
      }

      if (response.status === 200) {
        setPolicyData(response.data.results);
        setCount(response.data.count);
        setDataCount(response.data.count);
        if (status === null) {
          const policyNames = response.data.results.map((policy) =>
            policy.policy.name.length !== 0
              ? policy.policy.name
              : policy.policy.policy_number
          );
          setPolicyNames(policyNames);
        }
        setLoader(true);
      } else {
        toast.error("Failed to fetch Policy data");
        setLoader(false);
      }
    } catch (error) {
      toast.error("Error fetching policy data: " + error.message);
      setLoader(false);
    }
  };

  const findUserPolicy = async (searchKey) => {
    // setLoader(false);
    try {
      let response;
      if (isRead!== null) {
        response = await searchUserPolicyData(
          header,
          searchKey,
          isRead,
          pageNo,
          recordsPerPage
        );
      } else {
        response = await searchUserPolicyData(
          header,
          searchKey,
          null,
          pageNo,
          recordsPerPage
        );
      }

      if (response.status === 200) {
        setPolicyData(response.data.results);
        setCount(response.data.count);
        setDataCount(response.data.count);
        setLoader(true);
      } else {
        toast.error("Failed to Search Course data");
        setLoader(false);
      }
    } catch (error) {
      toast.error("Error searching Course data: " + error.message);
      setLoader(false);
    }
  };

  const userPolicyData = [
    {
      id: 1,
      name: "Policy_August",
      version: "1.2",
      status: "read",
      updated_on: "4 Aug, 2024",
      type: "Clinical",
      fileURL:
        "https://carelearner.s3.eu-north-1.amazonaws.com/marketing/TestPolicyTemplate.pdf",
      author: "Dr. Jane Doe",
    },
    {
      id: 2,
      name: "Policy_September",
      version: "2.0",
      status: "pending",
      updated_on: "12 Sep, 2024",
      type: "HR",
      fileURL:
        "https://carelearner.s3.eu-north-1.amazonaws.com/marketing/TestPolicyTemplate.pdf",
      author: "Mr. John Smith",
    },
    {
      id: 3,
      name: "Policy_July",
      version: "3.1",
      status: "overdue",
      updated_on: "20 Jul, 2024",
      type: "Estates",
      fileURL:
        "https://carelearner.s3.eu-north-1.amazonaws.com/marketing/TestPolicyTemplate.pdf",
      author: "Ms. Emily Green",
    },
    {
      id: 4,
      name: "Policy_October",
      version: "1.3",
      status: "pending",
      updated_on: "30 Oct, 2024",
      type: "Health and Safety",
      fileURL:
        "https://carelearner.s3.eu-north-1.amazonaws.com/marketing/TestPolicyTemplate.pdf",
      author: "Dr. Alice Brown",
    },
    {
      id: 5,
      name: "Policy_November",
      version: "2.5",
      status: "read",
      updated_on: "10 Nov, 2024",
      type: "Finance",
      fileURL:
        "https://carelearner.s3.eu-north-1.amazonaws.com/marketing/TestPolicyTemplate.pdf",
      author: "Mr. Michael Lee",
    },
    {
      id: 6,
      name: "Policy_December",
      version: "3.0",
      status: "overdue",
      updated_on: "5 Dec, 2024",
      type: "Legal",
      fileURL:
        "https://carelearner.s3.eu-north-1.amazonaws.com/marketing/TestPolicyTemplate.pdf",
      author: "Ms. Sarah Wilson",
    },
    {
      id: 7,
      name: "Policy_January",
      version: "1.0",
      status: "read",
      updated_on: "15 Jan, 2024",
      type: "Operations",
      fileURL:
        "https://carelearner.s3.eu-north-1.amazonaws.com/marketing/TestPolicyTemplate.pdf",
      author: "Mr. David Brown",
    },
    {
      id: 8,
      name: "Policy_February",
      version: "1.8",
      status: "pending",
      updated_on: "20 Feb, 2024",
      type: "IT",
      fileURL:
        "https://carelearner.s3.eu-north-1.amazonaws.com/marketing/TestPolicyTemplate.pdf",
      author: "Dr. Olivia Clarke",
    },
  ];

  let header = {
    Authorization: "Token " + state.token,
  };

  const handleCourseSelect = (courseName) => {
    setSearchText(courseName);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  return (
    <>
      {!loader ? (
        <AppLoader />
      ) : (
        <div className="user_policy">
          <div className="user_policy-title">
            <div className="user_policy-title-text">Policy</div>
            <div className="user_policy-title-search_box">
              <SearchBox
                searchData={policyNames}
                onValueClick={handleCourseSelect}
                onClearSearch={handleClearSearch}
              />
            </div>
          </div>

          <UserPolicyItems
            userPolicies={policyData}
            header={header}
            setStatus={setIsRead}
            status={isRead}
            policyCount={dataCount}
          />

          {dataCount > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      )}
    </>
  );
};

export default UserPolicy;
