import React, { useState, useEffect, useContext } from "react";
import AdminNavbar from "../components/AdminNavbar";
import AdminHeader from "../../../learning/admin/components/AdminHeader";
import "./RiskAdmin.scss"
import AppRiskRouter from "../../AppRiskRouter";

const RiskAdmin = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setShowSidebar(false);
      } else {
        setShowSidebar(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  
  return (
    <div className="risk_admin">
      <div className="risk_admin-body">
        <div className="risk_admin-body-sidebar">
        <AdminNavbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
        </div>
        <div className="risk_admin-body-content">
          <div className="risk_admin-body-content-header">
            <AdminHeader toggleSidebar={toggleSidebar}  />
          </div>
          <div className="risk_admin-body-content-elements">
            <AppRiskRouter/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskAdmin;
