import React, { useState, useRef, useEffect } from 'react';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import './SelectMenu.scss'; // Ensure your CSS is linked here

const SelectMenu = ({
  label,
  options,
  selectedOption,
  onOptionSelect,
  customStyles = {}
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleMenuToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    onOptionSelect(option);
    setIsOpen(false);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="select-menu-container"
      style={customStyles.container}
      onClick={handleMenuToggle}
      ref={menuRef}
    >
      <label style={customStyles.label}>{label}</label>
      <span style={customStyles.selectedOption}>
        {selectedOption}
        {isOpen ? <FaCaretUp /> : <FaCaretDown />}
      </span>
      {isOpen && (
        <div className="select-menu-list" style={customStyles.list}>
          {options &&
            options.map((option) => (
              <div
                key={option.id}
                className="select-menu-item"
                style={customStyles.item}
                onClick={() => handleOptionSelect(option)}
              >
                {option.name}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SelectMenu;
