import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaRegTrashAlt, FaEye, FaPencilAlt, FaPhone  } from "react-icons/fa";
import toast from "react-hot-toast";
import AdminCustomCheckbox from "../../../learning/admin/components/AdminCustomCheckbox";
import PopupModal from "../../../learning/admin/components/PopupModal";
import SearchBox from "../../../learning/user/components/SearchBox";
import AppPagination from "../../../learning/admin/components/AppPagination";
import "../../../theme/_buttons.scss";
import "./AdminIncidentReports.scss";

const AdminIncidentReports = () => {
  const navigate = useNavigate();
  const [isRemove, setIsRemove] = useState(false);
  const [key, setKey] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [checkedData, setCheckedData] = useState([]);
  const [data, setData] = useState([]);
  const itemClass = checkedData.length > 0 ? "active" : "inactive";
  const [requestNames, setRequestNames] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [incidentData, setIncidentData] = useState([
    {
      id: 1,
      reporter_name: "John Doe",
      reporter_email: "john.doe@example.com",
      reporter_phone: "+1234567890",
      date_of_incident: "2024-09-01",
      time: "10:30 AM",
      location: "Ward 5B",
      type: "Medication Error",
      severity: "High",
      file: "incident_photo_1.jpg",
    },
    {
      id: 2,
      reporter_name: "Jane Smith",
      reporter_email: "jane.smith@example.com",
      reporter_phone: "+0987654321",
      date_of_incident: "2024-09-03",
      time: "2:00 PM",
      location: "ICU",
      type: "Patient Fall",
      severity: "Critical",
      file: "incident_photo_2.jpg",
    },
    // Add more incidents as needed
  ]);

  var recordsPerPage = 5;
  const [pageNo, setPageNo] = useState(1);
  const [dataCount, setDataCount] = useState(5);

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleNavigate = () => {
    navigate("/incident/admin/incident-report-form/");
  };

  const handleFileDownload = (fileUrl, fileName) => {
    try {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // toast.success("File downloaded successfully!");
    } catch (error) {
      toast.error("Failed to download the file.");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleRemoveData = async () => {
    if (checkedData.length === 0) {
      toast.error("No items selected for deletion.");
      return;
    }
    toast.success("Data removed successfully.");
    setCheckedData([]);
    setIsRemove(false);
    setKey(!key);
  };

  const handleCancelClick = () => {
    setCheckedData([]);
    setIsRemove(false);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    setKey(!key);
  };

  const handleRequestSearch = (searchText) => {
    setSearchKey(searchText);
    return requestNames;
  };
  const handleClearRequestSearch = () => {
    setSearchKey("");
    setKey(!key);
  };
  const handleRequestValueClick = (requestName) => {
    setSearchKey(requestName);
  };

  const formatFileName = (url) => {
    return url.split("/").pop().split("?")[0];
  };

  return (
    <div className="admin_incident_reports">
      <div className="admin_incident_reports-title">Incident Reports</div>
      <div className="admin_incident_reports-body">
        <div className="admin_incident_reports-body-tab-content">
          <div className="admin_incident_reports-body-tab-content-top">
            
              <button
                className="primary-button"
                style={{ width: "150px" }}
                onClick={() => handleNavigate()}
              >
                <FaPlus />
                Report Incident
              </button>
            
            {/* <div className="admin_incident_reports-body-tab-content-top-search_box">
              <SearchBox
                searchData={[]}
                onSearch={handleRequestSearch}
                onValueClick={handleRequestValueClick}
                onClearSearch={handleClearRequestSearch}
                useDynamicSearch={true}
              />
            </div> */}
          </div>
          <div className="admin_incident_reports-body-tab-content-table">
            <div className="admin_incident_reports-body-tab-content-table-head">
              {incidentData && (
                <>
                  <div className="admin_incident_reports-body-tab-content-table-head-item">
                    <AdminCustomCheckbox
                      selectAll={true}
                      setCheckedData={setCheckedData}
                      checkedData={checkedData}
                      responseData={incidentData}
                    />
                    Reporter Name
                  </div>

                  <div className="admin_incident_reports-body-tab-content-table-head-item">
                    Date of Incident
                  </div>
                  <div className="admin_incident_reports-body-tab-content-table-head-item">
                    Severity
                  </div>
                  <div className="admin-survey-body-tab-content-table-head-item">
                    <FaRegTrashAlt
                      onClick={() => {
                        checkedData.length > 0
                          ? setIsRemove(true)
                          : handleRemoveData();
                      }}
                      className={itemClass}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="admin_incident_reports-body-tab-content-table-body">
              {incidentData &&
                incidentData.map((item) => (
                  <div
                    className="admin_incident_reports-body-tab-content-table-body-row"
                    key={item.id}
                  >
                    <div className="admin_incident_reports-body-tab-content-table-body-row-item">
                      <AdminCustomCheckbox
                        selectAll={false}
                        data_id={item.id}
                        setCheckedData={setCheckedData}
                        checkedData={checkedData}
                      />
                      <div className="admin_incident_reports-body-tab-content-table-body-row-item-text">
                        <div className="admin_incident_reports-body-tab-content-table-body-row-item-text-main">
                        {item.reporter_name}
                        </div>
                        <div className="admin_incident_reports-body-tab-content-table-body-row-item-text-sub">
                          {/* Reported on {formatDate(item.date_of_incident)} */}
                          <FaPhone/>{item.reporter_phone}

                        </div>
                        <div className="admin_incident_reports-body-tab-content-table-body-row-item-text-sub">
                    {item.reporter_email}
                        </div>
                      </div>
                    </div>
                    <div className="admin_incident_reports-body-tab-content-table-body-row-item">
                      {formatDate(item.date_of_incident)}
                    </div>
                    <div className="admin_incident_reports-body-tab-content-table-body-row-item">
                      {item.severity}
                    </div>
                    <div className="admin_incident_reports-body-tab-content-table-body-row-item">
                      <div
                        className={`secondary-button admin_incident_reports-body-tab-content-table-body-row-item-button ${
                          openDropdownId === item.id ? "open" : ""
                        }`}
                        style={{ width: "95px" }}
                        onClick={() =>
                          handleFileDownload(
                            item.file,
                            `${formatFileName(item.file)}.jpg`
                          )
                        }
                      >
                        <FaEye /> Preview
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {dataCount > recordsPerPage && (
            <AppPagination
              setCurrentPage={setPageNo}
              currentPage={pageNo}
              recordsPerPage={recordsPerPage}
              data_count={dataCount}
            />
          )}
        </div>
      </div>

      {/* Popup Modal */}
      {isRemove && (
        <PopupModal setIsOpen={setIsRemove}>
          <div className="unenroll_confirm_modal-body">
            <div className="unenroll_confirm_modal-body-title">
              Remove Incident(s)
            </div>
            <div className="unenroll_confirm_modal-body-text">
              Are you sure you want to remove selected{" "}
              {checkedData.length < 2 ? "incident" : "incidents"}?
            </div>
            <div className="unenroll_confirm_modal-body-buttons">
              <button
                className="primary-button"
                onClick={() => {
                  handleRemoveData();
                }}
              >
                Confirm
              </button>
              <button className="secondary-button" onClick={handleCancelClick}>
                Cancel
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </div>
  );
};

export default AdminIncidentReports;
