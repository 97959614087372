import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminNavbar.scss";
import { AuthContext } from '../../../App';
import appLogo from "../../../learning/images/icons/logo.png";
import appTabLogo from "../../../learning/images/icons/mobile_logo.png";
import dashboardIcon from "../../../learning/images/icons/dashboard.png";
import logoutIcon from "../../../learning/images/icons/logout.png";
import incidentIcon from "../images/incident.png";

const AdminNavbar = ({ toggleNavbar, showNavbar }) => {
  const { state, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleTabClick = (index) => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 500) {
      toggleNavbar();
    }
    document.getElementsByClassName('incident_admin-navbar-content-tab selected')[0].classList.remove('selected')
    if(!index.currentTarget.classList.contains('selected')){
      index.currentTarget.classList.add('selected')
    }
  };
  const handleLogout = () => {
    dispatch({
        type: 'LOGOUT',
        payload: {
          user: null,
          token: null
        }
    })
    navigate('/');
  };  

  return (
    <div className={showNavbar ? "incident_admin-navbar show-navbar" : "incident_admin-navbar" }>
      <div className="incident_admin-navbar-content">
      <div className="incident_admin-navbar-content-logo">
        <div className="incident_admin-navbar-content-logo-img">
          <img src={appLogo} alt="logo" />
        </div>
        <div className="incident_admin-navbar-content-logo-tab">
          <img src={appTabLogo} alt="logo" />
        </div>
      </div>
        <div className="incident_admin-navbar-content-tab selected" key={1}
          onClick={(key) => {
            handleTabClick(key);
            navigate("");
          }}
        >
          <div className="incident_admin-navbar-content-tab-icon">
            <img src={dashboardIcon} alt="dashboard"></img>
          </div>
          <div className="incident_admin-navbar-content-tab-text">Dashboard</div>
        </div>
        <div className="incident_admin-navbar-content-tab" key={2}
          onClick={(key) => {
            handleTabClick(key);
            navigate("/incident/admin/incident-reports/");
          }}>
          <div className="incident_admin-navbar-content-tab-icon">
            <img src={incidentIcon} alt="incident_icon"></img>
          </div>
          <div className="incident_admin-navbar-content-tab-text">
           Incident 
          </div>
        </div>
        <div
          className="incident_admin-navbar-content-tab" key={3}
          onClick={(key) => {
            handleTabClick(key);
            handleLogout();
          }}
        >
          <div className="incident_admin-navbar-content-tab-icon">
            <img src={logoutIcon} alt="trainees"></img>
          </div>
          <div className="incident_admin-navbar-content-tab-text">Logout</div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
