import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const getUserPolicytData = async (
  header,
  is_read = null,
  page = 1,
  pageSize = 10
) => {
  try {
    const url =
      is_read !== null
        ? `api/policy-app/user/user-policies/?page=${page}&page_size=${pageSize}&is_read=${is_read}`
        : `api/policy-app/user/user-policies/?page=${page}&page_size=${pageSize}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const searchUserPolicyData = async (
  header,
  searchKey,
  is_read = null,
  page = 1,
  pageSize = 10
) => {
  try {
    const url =
      is_read !== null
        ? `api/policy-app/user/user-policies/?page=${page}&page_size=${pageSize}&is_read=${is_read}&search=${searchKey}`
        : `api/policy-app/user/user-policies/?page=${page}&page_size=${pageSize}&search=${searchKey}`;
    const res = await instance.get(url, {
      headers: header,
    });
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};

export const updateUserPolicy = async (header, instanceId, updatedData) => {
  try {
    const res = await instance.patch(
      `api/policy-app/user/user-policies/${instanceId}/`,
      updatedData,
      { headers: header }
    );
    return res;
  } catch (err) {
    return { status: "error", message: err };
  }
};
