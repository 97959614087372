import React, { useState } from "react";
import "./AdminToggler.scss";

const AdminToggler = ({ checked, onChange }) => {
  const [isToggled, setIsToggled] = useState(checked);

  const handleToggle = () => {
    const newState = !isToggled;
    setIsToggled(newState);

    if (onChange && typeof onChange === "function") {
      onChange(newState);
    }
  };

  return (
    <div
      className={`admin-toggle-switch ${isToggled ? "checked" : ""}`}
      onClick={handleToggle}
      style={{
        backgroundColor: isToggled ? "#569cd6" : "#38afb9", fontWeight:"500"
      }}
    >
      {!isToggled && <span className="toggle-text right">Admin</span>}
      <div className="admin-toggle-switch__slider" />
      {isToggled && <span className="toggle-text left">User</span>}
    </div>
  );
};

export default AdminToggler;
