import React, { useState, useRef } from "react";
import {
  FaTimes,
  FaDownload,
  FaCloudUploadAlt,
  FaTrashAlt,
  FaFileExcel,
  FaUpload,
} from "react-icons/fa";
import toast from "react-hot-toast";
import { downloadExcelTemplate, uploadExcelTemplate } from "../services/api.services";
import "./FileUploadPopup.scss";

const FileUploadPopup = ({ header, setIsFileUpload, setTaskId}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setUploadedFile(null); 
  };

  const handleFileUpload = (files) => {
    if (files.length > 0) {
      const file = files[0];
      const validFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel"
      ];

      if (!validFileTypes.includes(file.type)) {
        toast.error("Invalid file format. Please upload an Excel file.");
        return;
      }

      setUploadedFile(file);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileInputChange = (e) => {
    handleFileUpload(e.target.files);
  };

  const handleCancelUpload = () => {
    setUploadedFile(null);
    fileInputRef.current.value = null;
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else if (size < 1024 * 1024 * 1024)
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    else return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  };

  const handleDownloadTemplate = async () => {
    const res = await downloadExcelTemplate(header);
  
    if (res.status === "error") {
      toast.error("Failed to download template");
      return;
    }
    const contentType = res.headers["content-type"];
    const fileBlob = new Blob([res.data], { type: contentType });
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'create_trainee_template.xlsx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    toast.success("Template downloaded successfully");
  };

  const handleUploadTemplate = async () => {
    if (!uploadedFile) {
      toast.error("No file selected");
      return;
    }
    const response = await uploadExcelTemplate(uploadedFile, header);
    if (response.data.isSuccess) {
      setIsFileUpload(true);
      const newTaskId = response.data.dataInfo.task_id;
      setTaskId(newTaskId);
      localStorage.setItem("taskId", newTaskId);
      toast.success("File uploaded successfully");
      toggleModal();
    } else {
      toast.error("File upload failed");
    }
  };

  return (
    <div>
      <div className="file_upload_popup-button" onClick={toggleModal}>
        <div className="file_upload_popup-button-text">Bulk Upload</div>
      </div>

      {isOpen && (
        <div className="file_upload_popup-overlay">
          <div className="file_upload_popup-content">
            <div
              onClick={toggleModal}
              className="file_upload_popup-content-close_button"
            >
              <FaTimes />
            </div>
            <div className="file_upload_popup-content-title">Upload File</div>
            <div
              className="file_upload_popup-content-body"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {!uploadedFile ? (
                <>
                  <div className="file_upload_popup-content-body-top">
                    <div className="file_upload_popup-content-body-top-download_button" onClick={handleDownloadTemplate}>
                      <div className="file_upload_popup-content-body-top-download_button-icon">
                        <FaDownload />
                      </div>
                      <div className="file_upload_popup-content-body-top-download_button-text">
                        Get Template
                      </div>
                    </div>
                  </div>

                  <div className="file_upload_popup-content-body-bottom">
                    <div className="file_upload_popup-content-body-bottom-icon">
                      <FaCloudUploadAlt />
                    </div>
                    <div className="file_upload_popup-content-body-bottom-drag_text">
                      Drag files to upload
                    </div>
                    <div className="file_upload_popup-content-body-bottom-subtext">
                      or
                    </div>
                    <div
                      className="file_upload_popup-content-body-bottom-button"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <div className="file_upload_popup-content-body-bottom-button-text">
                        Browse Files
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </div>
                </>
              ) : (
                <div className="file_upload_popup-content-body-uploaded">
                  <div className="file_upload_popup-content-body-uploaded-file">
                    <div className="file_upload_popup-content-body-uploaded-file-icon">
                      <FaFileExcel />
                    </div>
                    <div className="file_upload_popup-content-body-uploaded-file-info">
                      <div className="file_upload_popup-content-body-uploaded-file-info-name">
                        {uploadedFile.name}
                      </div>
                      <div className="file_upload_popup-content-body-uploaded-file-info-size">
                        {formatFileSize(uploadedFile.size)}
                      </div>
                    </div>
                  </div>
                  <div className="file_upload_popup-content-body-uploaded-action">
                    <div
                      className="file_upload_popup-content-body-uploaded-action-upload_button"
                      onClick={handleUploadTemplate}
                    >
                      <div className="file_upload_popup-content-body-uploaded-action-upload_button-icon">
                        <FaUpload />
                      </div>
                      <div className="file_upload_popup-content-body-uploaded-action-upload_button-text">
                        Upload
                      </div>
                    </div>
                    <div
                      className="file_upload_popup-content-body-uploaded-action-cancel_button"
                      onClick={handleCancelUpload}
                    >
                      <div className="file_upload_popup-content-body-uploaded-action-cancel_button-icon">
                        <FaTrashAlt />
                      </div>
                      <div className="file_upload_popup-content-body-uploaded-action-cancel_button-text">
                        Remove
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadPopup;
