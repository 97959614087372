import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import carelearnerLogo from "../../../learning/images/certifications/carelearner_logo.png";
import cpdRegistered from "../../../learning/images/certifications/cpd_registered.png";
import cpdProvider from "../../../learning/images/certifications/cpd_provider.png";
import icoCertificate from "../../../learning/images/certifications/ico_certification.png";
import pentafoldLogo from "../../../learning/images/certifications/pentafold_logo.png";
import isoCertificate from "../../../learning/images/certifications/iso_certification.png";
import cyberEssetialsCertificate from "../../../learning/images/certifications/cyber_essentials_certification.png";
import {
  getSurveyFormData,
  createSurveyResponse,
} from "../services/api.services";
import { getTemplateQuestionData } from "../../admin/services/api.services";
import "./SurveyForm.scss";
import toast from "react-hot-toast";
import AppLoader from "../../../learning/user/components/AppLoader";

const SurveyForm = ({ header = null, isReadOnly = false, id = null }) => {
  const { surveyCode } = useParams();
  const [questions, setQuestions] = useState([]);
  const [surveyName, setSurveyName] = useState("");
  const [responses, setResponses] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isSubmissionSuccess, setIsSubmissionSuccess] = useState(false);

  const formId = id || surveyCode; // Use id from prop or fallback to surveyCode from params

  useEffect(() => {
    const fetchSurveyData = async () => {
      if (isReadOnly && id) {
        // Fetch the template data for read-only mode
        const response = await getTemplateQuestionData(header, id);
        if (response.status !== "error") {
          setQuestions(response.data.questions);
          setSurveyName(response.data.name);
          const initialResponses = response.data.questions.map((question) => ({
            question_id: question.id,
            selected_answer: "",
          }));
          setResponses(initialResponses);
          setLoader(false);
        } else {
          console.error(response.message);
        }
      } else {
        // Fetch the survey data for editable mode
        const response = await getSurveyFormData(formId);
        if (response.status !== "error") {
          setQuestions(response.data.questions);
          setSurveyName(response.data.name);
          const initialResponses = response.data.questions.map((question) => ({
            question_id: question.id,
            selected_answer: "",
          }));
          setResponses(initialResponses);
          setLoader(false);
        } else {
          console.error(response.message);
        }
      }
    };

    fetchSurveyData();
  }, [formId, isReadOnly, id]);

  const handleInputChange = (questionId, value) => {
    if (isReadOnly) return; // Prevent input changes in read-only mode

    setResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.question_id === questionId
          ? { ...response, selected_answer: value.toString() }
          : response
      )
    );
  };

  const validateForm = () => {
    let isValid = true;

    const updatedQuestions = questions.map((question) => {
      const responseValue = responses.find(
        (response) => response.question_id === question.id
      )?.selected_answer;

      if (!responseValue) {
        question.error = true;
        isValid = false;
      } else if (
        question.type === "contact_info" &&
        !validateEmail(responseValue)
      ) {
        question.error = true;
        isValid = false;
      } else {
        question.error = false;
      }

      return question;
    });

    setQuestions(updatedQuestions);
    return isValid;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (isReadOnly) return; // Prevent submission in read-only mode

    if (validateForm()) {
      setLoader(true);
      try {
        const formattedResponses = responses.map((response) => ({
          question_id: response.question_id,
          selected_answer: response.selected_answer,
        }));

        const result = await createSurveyResponse(
          surveyCode,
          formattedResponses
        );

        if (result.status !== "error") {
          toast.success("Form submitted successfully");
          setIsSubmissionSuccess(true);
          setLoader(false);
        } else {
          toast.error(result.message || "An unexpected error occurred.");
        }
      } catch (error) {
        toast.error("An unexpected error occurred.");
      }
    } else {
      toast.error("Please complete all required fields.");
    }
  };

  return (
    <div className="survey_form">
      {!isReadOnly && <SurveyFormHeader />}
      <div
        className="survey_form_content"
        style={isReadOnly ? { padding: "5%" } : {}}
      >
        <div className="survey_form_content_head">
          <div className="survey_form_content_head_title">{surveyName}</div>
          <div className="survey_form_content_head_content">
            {!isReadOnly
              ? "This survey is conducted by CareLearner to collect your insights on your recent experiences with the care home. Your input is greatly appreciated and crucial for enhancing care quality. Thank you for your time!"
              : "Survey template preview. No input allowed."}
          </div>
        </div>
        <>
          {loader ? (
            <AppLoader />
          ) : isSubmissionSuccess ? (
            <ThankYouMessage />
          ) : (
            questions && (
              <div className="survey_form_content_body">
                {questions.map((question, index) => (
                  <div
                    key={question.id}
                    className="survey_form_content_body_question"
                    style={question.error ? { borderTop: "3px solid red" } : {}}
                  >
                    <div className="survey_form_content_body_question_text">
                      {index + 1}. {question.text}
                    </div>

                    {question.type === "contact_info" && (
                      <input
                        type="text"
                        name={question.id}
                        placeholder="Enter your email address"
                        value={
                          responses.find(
                            (response) => response.question_id === question.id
                          )?.selected_answer || ""
                        }
                        className="contact_info"
                        onChange={(e) =>
                          handleInputChange(question.id, e.target.value)
                        }
                        disabled={isReadOnly} // Disable input in read-only mode
                      />
                    )}

                    {question.type === "multiple_choice" && (
                      <div className="survey_form_content_body_question_options multiple_choice">
                        {question.options.map((option, index) => (
                          <div key={index} className="form__radio-group">
                            <input
                              id={`${question.id}_${index}`}
                              type="radio"
                              className="form__radio-input"
                              name={question.id}
                              value={option.value}
                              checked={
                                responses.find(
                                  (response) =>
                                    response.question_id === question.id
                                )?.selected_answer === option.value.toString()
                              }
                              onChange={(e) =>
                                handleInputChange(question.id, e.target.value)
                              }
                              disabled={isReadOnly} // Disable input in read-only mode
                            />
                            <label
                              htmlFor={`${question.id}_${index}`}
                              className="form__radio-label"
                            >
                              <span className="form__radio-button"></span>
                              {option.text}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}

                    {question.type === "scaling" && (
                      <div className="survey_form_content_body_question_options scaling">
                        {question.options.map((option, index) => (
                          <div key={index} className="form__radio-group">
                            <input
                              id={`${question.id}_${index}`}
                              type="radio"
                              className="form__radio-input"
                              name={question.id}
                              value={option.value}
                              checked={
                                responses.find(
                                  (response) =>
                                    response.question_id === question.id
                                )?.selected_answer === option.value.toString()
                              }
                              onChange={(e) =>
                                handleInputChange(question.id, e.target.value)
                              }
                              disabled={isReadOnly} // Disable input in read-only mode
                            />
                            <label
                              htmlFor={`${question.id}_${index}`}
                              className="form__radio-label"
                            >
                              <span className="form__radio-button"></span>
                              {option.text}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )
          )}
        </>

        <div className="survey_form_content_bottom">
          {!isSubmissionSuccess && (
            <button
              className="primary-button"
              onClick={handleSubmit}
              disabled={isReadOnly} // Disable submit button in read-only mode
            >
              Submit
            </button>
          )}
        </div>
      </div>
      {!isReadOnly && <SurveyFormFooter />}
    </div>
  );
};

export default SurveyForm;

const ThankYouMessage = () => {
  return (
    <div className="thank_you_message_container">
      <div className="thank_you_message">
        <h1>Thank you for completing the survey!</h1>
        <p>
          Your feedback is greatly appreciated and will help improve care
          quality.
          <br />
          You may now close this tab.
        </p>
      </div>
    </div>
  );
};

const SurveyFormHeader = () => (
  <div className="survey_form_header">
    <div className="survey_form_header_links">
      <img alt="carelearner" src={carelearnerLogo} />
      <div className="survey_form_header_links_help effect">Help</div>
      <div className="survey_form_header_links_contact effect">Contact</div>
    </div>
    <div className="survey_form_header_certificates">
      <img alt="cpd_approved_provider" src={cpdProvider} />
      <img alt="ico_certification" src={icoCertificate} />
      <img alt="cpd_registered" src={cpdRegistered} />
    </div>
  </div>
);

const SurveyFormFooter = () => (
  <div className="survey_form_footer">
    <div className="survey_form_footer_certifications">
      <div className="survey_form_footer_certifications_images">
        <div className="survey_form_footer_certifications_images_pentafold_logo">
          <span>Powered By</span>
          <img src={pentafoldLogo} alt="pentafold_logo" />
        </div>

        <img
          src={isoCertificate}
          alt="iso_certification"
          className="survey_form_footer_certifications_images_iso_certification"
        />
        <img
          src={cyberEssetialsCertificate}
          alt="cyber_essentials"
          className="survey_form_footer_certifications_images_cyber_essentials"
        />
      </div>
      <div className="survey_form_footer_certifications_info">
        <div className="survey_form_footer_certifications_info_item">
          <span className="survey_form_footer_certifications_info_item_key">
            Reg No.
          </span>{" "}
          <span>:</span>
          <span className="survey_form_footer_certifications_info_item_value">
            13960104
          </span>
        </div>
        <div className="survey_form_footer_certifications_info_item">
          <span className="survey_form_footer_certifications_info_item_key">
            VAT No.
          </span>{" "}
          <span>:</span>
          <span className="survey_form_footer_certifications_info_item_value">
            446678842
          </span>
        </div>
      </div>
    </div>
    <div className="survey_form_footer_links">
      <div className="survey_form_footer_links_catalogue">Catalogue</div>
      <div className="survey_form_footer_links_userguide">User Guide</div>
      <div className="survey_form_footer_links_adminguide">Admin Guide</div>
    </div>
  </div>
);
