import React, { useState, useContext} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FaArrowLeft,
} from "react-icons/fa";
import "./SurveyTemplate.scss";
import AdminCustomCheckbox from "../../../learning/admin/components/AdminCustomCheckbox";
import SurveyForm from "../../user/views/SurveyForm";
import { AuthContext } from "../../../App";

const SurveyTemplate = () => {
  const { state, dispatch } = useContext(AuthContext);
  let header = {
    Authorization: "Token " + state.token,
  };
  const location = useLocation();
  const navigate = useNavigate();
  const template = location.state;
  return (
    <div className="admin_survey_template">
      <div className="admin_survey_template-top">
        <div className="admin_survey_template-top-icon" onClick={()=> navigate("/compliance/admin/survey/")}>
          <FaArrowLeft />
        </div>
        <div className="admin_survey_template-top-title">
          <div className="admin_survey_template-top-title-text">Template</div>/
          <div className="admin_survey_template-top-title-template_name">
            {template.name}
          </div>
        </div>
      </div>
      <div className="admin_survey_template-body">
        <SurveyForm header={header} isReadOnly={true} id={template.id}/>
       
      </div>
    </div>
  );
};

export default SurveyTemplate;
