import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const getSurveyFormData = async (surveyCode) => {
  try {
    const res = await instance.get(`api/feedback-app/user/get-survey-form/`, {
      params: {
        survey_code: surveyCode, 
      },
    });
    return res;
  } catch (err) {
    return {
      status: "error",
      message: err.response ? err.response.data : "An unexpected error occurred",
    };
  }
};

export const createSurveyResponse = async (surveyCode, responseData) => {
  try {
    const res = await instance.post('api/feedback-app/user/create-survey-response/', {
      survey_code: surveyCode,
      response_data: responseData,  
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return res.data;  
  } catch (err) {
    return {
      status: "error",
      message: err.response ? err.response.data : "An unexpected error occurred",
    };
  }
};




