import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaArrowLeft,
  FaFileContract,
  FaCaretUp,
  FaCaretDown,
  FaTimes,
  FaDownload,
  FaCloudUploadAlt,
  FaTrashAlt,
  FaFilePdf,
  FaUpload,
  FaExchangeAlt,
} from "react-icons/fa";
import SelectMenu from "../../../common/components/SelectMenu";
import TagSelectMenu from "../../../common/components/TagSelectMenu";
import "./CreatePolicy.scss";
import {
  getTemplateData,
  createPolicy,
  getOrganisationData,
  getJobroles,
} from "../services/api.services";
import { AuthContext } from "../../../App";
import toast from "react-hot-toast";

const CreatePolicy = () => {
  const { state } = useContext(AuthContext);
  let header = {
    Authorization: "Token " + state.token,
  };
  const navigate = useNavigate();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [organisationData, setOrganisationData] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState("Select Template");
  const [error, setError] = useState({});
  const [jobroleData, setJobroleData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Select Type");
  // File Upload
  const [uploadedFile, setUploadedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedMethod, setSelectedMethod] = useState("useTemplate");
  const toggleMethod = () => {
    setSelectedMethod((prevMethod) =>
      prevMethod === "useTemplate" ? "uploadFile" : "useTemplate"
    );
  };
  const [policyFormData, setPolicyFormData] = useState({
    policy_author: "",
    policy_approver: "",
    template: "",
    type: "",
    jobroles: [],
    uploadedFile: null,
  });

  // const roleTags = [
  //   { id: "9005b69b-f7f1-4210-8132-c65bcd5614ae", name: "Staff" },
  //   { id: "aacebb55-86e0-4223-9932-adb85f3ddc0f", name: "Care Manager" },
  // ];

  const options = [
    { id: 1, name: "Clinical" },
    { id: 2, name: "HR" },
    { id: 3, name: "Estates" },
    { id: 4, name: "Health & Safety" },
  ];

  const handleTypeSelect = (option) => {
    setSelectedOption(option.name);
    setPolicyFormData((prevFormData) => ({
      ...prevFormData,
      type: option.name,
    }));
  };

  const handleTagSelect = (tag) => {
    setPolicyFormData((prevFormData) => {
      // Check if the tag already exists in the array
      if (!prevFormData.jobroles.includes(tag.id)) {
        return {
          ...prevFormData,
          jobroles: [...prevFormData.jobroles, tag.id],
        };
      }
      return prevFormData;
    });
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    fetchTemplateData();
    fetchOrganisationData();
    fetchJobroleData();
  }, []);

  const fetchTemplateData = async () => {
    try {
      let response = await getTemplateData(header);
      if (response.status === 200) {
        setTemplateData(response.data.results);
      } else {
        toast.error("Failed to fetch Template data");
      }
    } catch (error) {
      toast.error("Error fetching template data: " + error.message);
    }
  };

  const fetchOrganisationData = async () => {
    try {
      let response = await getOrganisationData(header);
      if (response.status === 200) {
        setOrganisationData(response.data.dataInfo);
      } else {
        toast.error("Failed to fetch Template data");
      }
    } catch (error) {
      toast.error("Error fetching template data: " + error.message);
    }
  };

  const fetchJobroleData = async () => {
    try {
      const response = await getJobroles(header);
      if (response.status === 200) {
        setJobroleData(response.data.results);
      } else {
        toast.error("Failed to fetch jobrole data");
      }
    } catch (error) {
      toast.error("Error fetching jobrole data: " + error.message);
    }
  };
  const roleTags = jobroleData.map((role) => ({
    id: role.id,
    name: role.name,
  }));

  const handleDropdownToggle = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template.name);
    setIsOpenDropdown(false);
    handleFormDataChange("template", template.id);
  };

  const handleFormDataChange = (field, value) => {
    setPolicyFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    // setFormErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [field]: false,
    // }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    handleFormDataChange(name, value);
  };

  const handleSubmit = async (status) => {
    policyFormData.status = status;
    if (selectedMethod === "uploadFile") {
      // Check for necessary fields when uploading a file
      if (
        !policyFormData.uploadedFile ||
        !policyFormData.type
      ) {
        toast.error("Please upload a file and select a type");
        return;
      }
    } else {
      // Check for necessary fields when using a template
      if (
        !policyFormData.policy_author ||
        !policyFormData.policy_approver ||
        !policyFormData.template ||
        !policyFormData.type
      ) {
        toast.error("Please fill out all required fields.");
        return;
      }
    }
    const loadingToastId = toast.loading("Creating policy...");
    try {
      const response = await createPolicy(header, policyFormData);
      toast.dismiss(loadingToastId);
      if (response.status === 201) {
        toast.success("Policy created successfully!");
        navigate("/policy/admin/policy/");
      } else {
        toast.dismiss(loadingToastId);
        toast.error("Failed to create policy.");
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      toast.error("Error creating policy: " + error.message);
    }
  };

  // Funtions for File Upload

  const handleUploadTemplate = async () => {
    if (!uploadedFile) {
      toast.error("No file selected");
      return;
    }
    toast.success("File uploaded successfully");
    // const response = await uploadExcelTemplate(uploadedFile, header);
    // if (response.data.isSuccess) {
    //   setIsFileUpload(true);
    //   const newTaskId = response.data.dataInfo.task_id;
    //   setTaskId(newTaskId);
    //   localStorage.setItem("taskId", newTaskId);
    //   toast.success("File uploaded successfully");
    //   toggleModal();
    // } else {
    //   toast.error("File upload failed");
    // }
  };
  const handleFileUpload = (files) => {
    if (files.length > 0) {
      const file = files[0];
      const validFileType = "application/pdf";

      if (file.type !== validFileType) {
        toast.error("Invalid file format. Please upload a PDF file.");
        return;
      }
      setUploadedFile(file);
      setPolicyFormData((prevFormData) => ({
        ...prevFormData,
        uploadedFile: file,
      }));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileInputChange = (e) => {
    handleFileUpload(e.target.files);
  };

  const handleCancelUpload = () => {
    setUploadedFile(null);
    fileInputRef.current.value = null;
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    else if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
    else if (size < 1024 * 1024 * 1024)
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    else return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  };

  return (
    <div className="create-policy">
      <div className="create-policy-top">
        <div
          className="create-policy-top-icon"
          onClick={() => navigate("/policy/admin/policy/")}
        >
          <FaArrowLeft />
        </div>
        <div className="create-policy-top-title">
          <div className="create-policy-top-title-text">New Policy</div>
        </div>
      </div>
      <div className="create-policy-body">
        <div className="create-policy-body-title">
          Create Policy <FaFileContract />
        </div>

        <div className="create-policy-body-form">
          {organisationData && (
            <div className="create-policy-body-form-org">
              <div
                className="create-policy-body-form-org-profile"
                style={
                  organisationData.logo
                    ? { backgroundColor: "transparent" }
                    : {}
                }
              >
                {organisationData.logo ? (
                  <img
                    src={organisationData.logo}
                    alt={organisationData.name}
                    className="create-policy-body-form-org-logo"
                  />
                ) : (
                  organisationData.shortname
                )}
              </div>
              <div className="create-policy-body-form-org-name">
                {organisationData.name}
              </div>
              <div className="create-policy-body-form-org-email">
                {organisationData.admin_email}
              </div>
            </div>
          )}

          <div className="create-policy-body-form-inputs">
            <div className="create-policy-body-form-inputs-button">
              <button
                className="primary-button"
                style={{ width: "max-content" }}
                onClick={toggleMethod}
              >
                <FaExchangeAlt
                  style={{ color: "white", marginInlineEnd: "10px" }}
                />
                {selectedMethod === "useTemplate"
                  ? "Upload Policy Template"
                  : "Use Template"}{" "}
              </button>
            </div>
            {selectedMethod === "useTemplate" && (
              <>
                <div className="create-policy-body-form-inputs-input">
                  <label>Policy Author</label>
                  <input
                    type="text"
                    name="policy_author"
                    value={policyFormData.policy_author}
                    onChange={handleInputChange}
                    style={{ background: "white" }}
                  />
                </div>

                <div className="create-policy-body-form-inputs-input">
                  <label>Policy Approver</label>
                  <input
                    type="text"
                    name="policy_approver"
                    value={policyFormData.policy_approver}
                    onChange={handleInputChange}
                  />
                </div>

                <div
                  className="create-policy-body-form-inputs-input"
                  onClick={handleDropdownToggle}
                  ref={dropdownRef}
                >
                  <label>Template</label>
                  <span>
                    {selectedTemplate}
                    {isOpenDropdown ? <FaCaretUp /> : <FaCaretDown />}
                  </span>
                  {isOpenDropdown && (
                    <div className="dropdown-list">
                      {templateData &&
                        templateData.map((template) => (
                          <div
                            key={template.id}
                            className="dropdown-item"
                            onClick={() => handleSelectTemplate(template)}
                          >
                            {template.name}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </>
            )}
            <SelectMenu
              label="Type"
              options={options}
              selectedOption={selectedOption}
              onOptionSelect={handleTypeSelect}
              // customStyles={}
            />
            {jobroleData.length > 0 ? (
              <TagSelectMenu
                label="Roles"
                options={roleTags}
                handleSelectTag={handleTagSelect}
              />
            ) : (
              <p>Loading job roles...</p> // or a loading spinner
            )}
          </div>
        </div>
        {selectedMethod === "uploadFile" && (
          <div className="create-policy-body-file">
            <div
              className="create-policy-body-file-file_upload"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {!uploadedFile ? (
                <>
                  <div className="create-policy-body-file-file_upload-bottom">
                    <div className="create-policy-body-file-file_upload-bottom-icon">
                      <FaCloudUploadAlt />
                    </div>
                    <div className="create-policy-body-file-file_upload-bottom-drag_text">
                      Drag files to upload
                    </div>
                    <div className="create-policy-body-file-file_upload-bottom-subtext">
                      or
                    </div>
                    <div
                      className="create-policy-body-file-file_upload-bottom-button"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <div className="create-policy-body-file-file_upload-bottom-button-text">
                        Browse Files
                      </div>
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </div>
                </>
              ) : (
                <div className="create-policy-body-file-file_upload-uploaded">
                  <div className="create-policy-body-file-file_upload-uploaded-file">
                    <div className="create-policy-body-file-file_upload-uploaded-file-icon">
                      <FaFilePdf />
                    </div>
                    <div className="create-policy-body-file-file_upload-uploaded-file-info">
                      <div className="create-policy-body-file-file_upload-uploaded-file-info-name">
                        {uploadedFile.name}
                      </div>
                      <div className="create-policy-body-file-file_upload-uploaded-file-info-size">
                        {formatFileSize(uploadedFile.size)}
                      </div>
                    </div>
                  </div>
                  <div className="create-policy-body-file-file_upload-uploaded-action">
                    {/* <div
                      className="create-policy-body-file-file_upload-uploaded-action-upload_button"
                      onClick={handleUploadTemplate}
                    >
                      <div className="create-policy-body-file-file_upload-uploaded-action-upload_button-icon">
                        <FaUpload />
                      </div>
                      <div className="create-policy-body-file-file_upload-uploaded-action-upload_button-text">
                        Upload
                      </div>
                    </div> */}
                    <div
                      className="create-policy-body-file-file_upload-uploaded-action-cancel_button"
                      onClick={handleCancelUpload}
                    >
                      <div className="create-policy-body-file-file_upload-uploaded-action-cancel_button-icon">
                        <FaTrashAlt />
                      </div>
                      <div className="create-policy-body-file-file_upload-uploaded-action-cancel_button-text">
                        Remove
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="create-policy-body-bottom">
          <button
            className="secondary-button"
            onClick={() => handleSubmit("draft")}
          >
            Save as Draft
          </button>
          <button
            className="primary-button"
            onClick={() => handleSubmit("published")}
          >
            Create Policy
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatePolicy;
